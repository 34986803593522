import React from "react";
import classnames from "classnames";
import BarChart from "../components/BarChart";
import {
  parentStrings,
  copy as chartDescriptions,
  items,
} from "../strings/strings";
import { FormattedMessage } from "react-intl";
import { slugify } from "../utils/helpers";

export default class Viz3 extends React.Component {
  render() {
    const id = this.props.id;
    const item = this.props.item;
    const keys = this.props.keys;
    const showKeyTitles = this.props.showKeyTitles;

    const itemClasses = classnames(
      "story-chart-item",
      "story-chart-item--viz3",
      { "story-chart-item--has-keys": this.props.showKeyTitles }
    );

    return (
      <div className={itemClasses}>
        <div className="story-chart-item__header">
          <div className="story-chart-item__title">
            <FormattedMessage {...items["item-" + slugify(item.name)]} />
          </div>
          {item.description && (
            <div className="story-chart-item__description">
              <FormattedMessage
                {...items["item-" + slugify(item.name) + "-description"]}
              />
              {item.description}
            </div>
          )}
        </div>
        <div className="story-chart-item__keys">
          {keys.map((key, j) => {
            let percentage = item[key].percentage;
            let hasNum = item[key].amount !== undefined;
            let keyClasses = classnames(
              "story-chart-item__key",
              "story-chart-item__content",
              { "story-chart-item__key--has-number": hasNum }
            );

            let keyTitle = "";
            if (showKeyTitles) {
              keyTitle = (
                <FormattedMessage {...parentStrings["short-" + key]} />
              );
              if (this.props.replaceWithMums && key === "gendermums") {
                keyTitle = "Mums";
              }
            }

            return (
              <div key={j} className={keyClasses}>
                {showKeyTitles && (
                  <div className="story-chart-item__key-title">{keyTitle}</div>
                )}

                <div className="story-chart-item__charts">
                  <div className="story-chart-item__chart">
                    <BarChart percentage={percentage} />
                    {hasNum && (
                      <span className="story-chart-item__chart-title">
                        <FormattedMessage
                          {...chartDescriptions[id + ".percentage"]}
                        />
                      </span>
                    )}
                  </div>
                  {hasNum && (
                    <div className="story-chart-item__chart">
                      <span className="story-chart-item__number">
                        {item[key].amount}
                      </span>
                      <span className="story-chart-item__chart-title">
                        <FormattedMessage
                          {...chartDescriptions[id + ".amount"]}
                        />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
