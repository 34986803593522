import { defineMessages } from 'react-intl';
    export const copy = defineMessages({
    "question": {
        "id": "copy-question",
        "defaultMessage": "Question asked",
        "description": "Source title"
    },
    "notes": {
        "id": "copy-notes",
        "defaultMessage": "Notes",
        "description": "Source title"
    },
    "unweighted": {
        "id": "copy-unweighted",
        "defaultMessage": "Unweighted base",
        "description": "Source title"
    },
    "weighted": {
        "id": "copy-weighted",
        "defaultMessage": "Weighted base",
        "description": "Source title"
    },
    "bases-by-platform": {
        "id": "copy-bases-by-platform",
        "defaultMessage": "Bases by platform",
        "description": "Source title"
    },
    "title": {
        "id": "copy-title",
        "defaultMessage": "Meet the Parents",
        "description": "Name of app"
    },
    "about": {
        "id": "copy-about",
        "defaultMessage": "About",
        "description": "About link in header"
    },
    "uk": {
        "id": "copy-uk",
        "defaultMessage": "UK",
        "description": "Country name front page"
    },
    "canada": {
        "id": "copy-canada",
        "defaultMessage": "Canada",
        "description": "Country name front page"
    },
    "brazil": {
        "id": "copy-brazil",
        "defaultMessage": "Brazil",
        "description": "Country name front page"
    },
    "australia": {
        "id": "copy-australia",
        "defaultMessage": "Australia",
        "description": "Country name front page"
    },
    "mexico": {
        "id": "copy-mexico",
        "defaultMessage": "Mexico",
        "description": "Country name front page"
    },
    "spain": {
        "id": "copy-spain",
        "defaultMessage": "Spain",
        "description": "Country name front page"
    },
    "us": {
        "id": "copy-us",
        "defaultMessage": "US",
        "description": "Country name front page"
    },
    "tech1.percentage": {
        "id": "copy-tech1.percentage",
        "defaultMessage": "% who own",
        "description": "Chart description"
    },
    "tech1.amount": {
        "id": "copy-tech1.amount",
        "defaultMessage": "average owned",
        "description": "Chart description"
    },
    "select-country": {
        "id": "copy-select-country",
        "defaultMessage": "Select a country to begin",
        "description": "Country selector title"
    },
    "full-story": {
        "id": "copy-full-story",
        "defaultMessage": "View full story",
        "description": "Button text"
    },
    "country-page-title": {
        "id": "copy-country-page-title",
        "defaultMessage": "Meet the Parents in {nationality}",
        "description": "Country page title"
    },
    "copyright": {
        "id": "copy-copyright",
        "defaultMessage": "Facebook &copy {year}",
        "description": "Copyright text"
    },
    "back-to-link": {
        "id": "copy-back-to-link",
        "defaultMessage": "Back to Facebook IQ",
        "description": "Footer link"
    },
    "respondents": {
        "id": "copy-respondents",
        "defaultMessage": "Respondents",
        "description": "Respondents"
    },
    "source-title": {
        "id": "copy-source-title",
        "defaultMessage": "Source",
        "description": "Source title"
    },
    "source": {
        "id": "copy-source",
        "defaultMessage": "Facebook Global Parents Survey by Ipsos Media CT, March–April 2015.",
        "description": "Source copy"
    },
    "source-blurb": {
        "id": "copy-source-blurb",
        "defaultMessage": "Facebook IQ commissioned Ipsos Media CT to carry out an online study of self-identified parents aged 25–65 in seven markets: Australia, Brazil, Canada, Mexico, Spain, UK and US. The study was conducted with 1,000 respondents per market.",
        "description": "Source blurb"
    },
    "menu-tooltip": {
        "id": "copy-menu-tooltip",
        "defaultMessage": "Explore the data",
        "description": "Text for mobile menu tooltip"
    },
    "not-available": {
        "id": "copy-not-available",
        "defaultMessage": "N/A",
        "description": "No data text short (3 chars max)"
    }
});