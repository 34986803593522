import { defineMessages } from 'react-intl';
    export const navigation = defineMessages({
    "country": {
        "id": "navigation-country",
        "defaultMessage": "Explore the data for",
        "description": "Navigation item"
    },
    "brazil": {
        "id": "navigation-brazil",
        "defaultMessage": "Brazil",
        "description": "Navigation item"
    },
    "australia": {
        "id": "navigation-australia",
        "defaultMessage": "Australia",
        "description": "Navigation item"
    },
    "canada": {
        "id": "navigation-canada",
        "defaultMessage": "Canada",
        "description": "Navigation item"
    },
    "mexico": {
        "id": "navigation-mexico",
        "defaultMessage": "Mexico",
        "description": "Navigation item"
    },
    "spain": {
        "id": "navigation-spain",
        "defaultMessage": "Spain",
        "description": "Navigation item"
    },
    "us": {
        "id": "navigation-us",
        "defaultMessage": "US",
        "description": "Navigation item"
    },
    "uk": {
        "id": "navigation-uk",
        "defaultMessage": "UK",
        "description": "Navigation item"
    },
    "fam": {
        "id": "navigation-fam",
        "defaultMessage": "Family life",
        "description": "Navigation item"
    },
    "fam5": {
        "id": "navigation-fam5",
        "defaultMessage": "All change",
        "description": "Navigation item"
    },
    "fam1": {
        "id": "navigation-fam1",
        "defaultMessage": "Now vs then",
        "description": "Navigation item"
    },
    "fam2": {
        "id": "navigation-fam2",
        "defaultMessage": "Day-to-day",
        "description": "Navigation item"
    },
    "fam3": {
        "id": "navigation-fam3",
        "defaultMessage": "Core values",
        "description": "Navigation item"
    },
    "fam4": {
        "id": "navigation-fam4",
        "defaultMessage": "Chore war",
        "description": "Navigation item"
    },
    "fam4a": {
        "id": "navigation-fam4a",
        "defaultMessage": "I do",
        "description": "Navigation item"
    },
    "fam4b": {
        "id": "navigation-fam4b",
        "defaultMessage": "I don't",
        "description": "Navigation item"
    },
    "con": {
        "id": "navigation-con",
        "defaultMessage": "Spending",
        "description": "Navigation item"
    },
    "con1": {
        "id": "navigation-con1",
        "defaultMessage": "Family spending",
        "description": "Navigation item"
    },
    "con2": {
        "id": "navigation-con2",
        "defaultMessage": "Informed factor",
        "description": "Navigation item"
    },
    "con3": {
        "id": "navigation-con3",
        "defaultMessage": "Decision makers",
        "description": "Navigation item"
    },
    "con3a": {
        "id": "navigation-con3a",
        "defaultMessage": "Parent only",
        "description": "Navigation item"
    },
    "con3b": {
        "id": "navigation-con3b",
        "defaultMessage": "Parent",
        "description": "Navigation item"
    },
    "con3c": {
        "id": "navigation-con3c",
        "defaultMessage": "Partner",
        "description": "Navigation item"
    },
    "con3d": {
        "id": "navigation-con3d",
        "defaultMessage": "Kids",
        "description": "Navigation item"
    },
    "con3e": {
        "id": "navigation-con3e",
        "defaultMessage": "Other kids",
        "description": "Navigation item"
    },
    "con3f": {
        "id": "navigation-con3f",
        "defaultMessage": "Other adults",
        "description": "Navigation item"
    },
    "tech": {
        "id": "navigation-tech",
        "defaultMessage": "Technology",
        "description": "Navigation item"
    },
    "tech1": {
        "id": "navigation-tech1",
        "defaultMessage": "Devices owned",
        "description": "Navigation item"
    },
    "tech5": {
        "id": "navigation-tech5",
        "defaultMessage": "Mobile parenting",
        "description": "Navigation item"
    }
});