import { connect } from 'react-redux';
import Navigation from '../components/Navigation';
import { selectParent, close } from '../actions/navigation';
import history from '../utils/history';

function mapStateToProps(state) {
    return {
        items: state.navigation.items,
        selected: state.navigation.selected,
        country: state.countries.selected,
        stories: state.stories.stories
    }
}

function mapDispatchToProps(dispatch) {
    return {
        onParentSelect: (level, id) => { dispatch(selectParent(level, id)) },
        onItemSelect: (item, level, id) => {}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
