import { countries as constants } from "../constants/constants";
import { combineReducers } from "redux";
import config from "../config.json";

function selected(state = null, action) {
  switch (action.type) {
    case constants.SET_COUNTRY:
      return action.country;
    default:
      return state;
  }
}

function menuOpen(state = false, action) {
  switch (action.type) {
    case constants.OPEN:
      return true;
    case constants.CLOSE:
      return false;
    case constants.TOGGLE:
      return !state;
    default:
      return state;
  }
}

const countriesInitial = config.countries;

function countries(state = countriesInitial, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export default combineReducers({
  selected,
  countries,
  menuOpen,
});
