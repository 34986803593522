import React from "react";
import {
  sources as sourceStrings,
  copy,
  items,
  parentStrings,
} from "../strings/strings";
import { injectIntl, FormattedHTMLMessage } from "react-intl";
import { slugify } from "../utils/helpers";
import classnames from "classnames";

class Sources extends React.Component {
  render() {
    const story = this.props.story;
    const keys = this.props.keys;
    const data = story.data[this.props.country];

    let sources = [
      {
        title: this.props.intl.formatMessage(copy["question"]),
        value: this.props.intl.formatMessage(
          sourceStrings["source-question-" + story.id]
        ),
      },
      {
        title: this.props.intl.formatMessage(copy["notes"]),
        value: "",
      },
    ];

    if (sourceStrings["source-notes-" + story.id]) {
      sources[1].value = this.props.intl.formatMessage(
        sourceStrings["source-notes-" + story.id]
      );
    }

    // get key notes
    keys.forEach((key) => {
      if (parentStrings["note-" + key]) {
        sources[1].value +=
          " " + this.props.intl.formatMessage(parentStrings["note-" + key]);
      }
    });

    if (sources[1].value === "") {
      delete sources[1];
    }

    if (data.unweighted_base) {
      sources.push({
        title: this.props.intl.formatMessage(copy["respondents"]),
        value: data.unweighted_base,
      });
    }

    if (data.bases) {
      sources.push({
        title: this.props.intl.formatMessage(copy["bases-by-platform"]),
        value:
          data.bases
            .map((base) => {
              return (
                this.props.intl.formatMessage(
                  items["item-" + slugify(base.platform)]
                ) +
                ": " +
                base.base
              );
            })
            .join(", ") + ".",
      });
    }

    sources.push({
      printOnly: true,
      title: this.props.intl.formatMessage(copy["source-title"]),
      value: this.props.intl.formatMessage(copy["source"]),
    });

    sources.push({
      printOnly: true,
      value: this.props.intl.formatMessage(copy["source-blurb"]),
    });

    sources.push({
      printOnly: true,
      value: (
        <FormattedHTMLMessage
          {...copy["copyright"]}
          values={{ year: new Date().getFullYear() }}
        />
      ),
    });

    const renderSources = sources.map((source, i) => {
      const classes = classnames("sources__source", {
        "sources__source--print-only": source.printOnly,
      });

      return (
        <div className={classes} key={i}>
          {source.title && <div className="sources__title">{source.title}</div>}
          <div className="sources__value">{source.value}</div>
        </div>
      );
    });

    return <div className="sources">{renderSources}</div>;
  }
}

export default injectIntl(Sources);
