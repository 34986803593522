import React from "react";
import { connect } from "react-redux";
import Link from "../containers/Link";
import Header from "../components/Header";
import Navigation from "../containers/Navigation";
import CountryNavigation from "../containers/CountryNavigation";
import classnames from "classnames";
import { toggle, close } from "../actions/navigation";

class Main extends React.Component {
  render() {
    let classes = classnames("page page--no-menu", {
      "page--menu-is-open": this.props.navigationOpen,
    });
    return (
      <div className={classes}>
        <div className="page__header">
          <Header disableMenu history={this.props.history} />
        </div>
        <div className="page__wrapper-top">
          <main id="wrapper" className="page__wrapper">
            <div className="page__body">{this.props.children}</div>
          </main>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    navigationOpen: state.navigation.isOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    menuToggle: () => {
      dispatch(toggle());
    },
    menuClose: () => {
      dispatch(close());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Main);
