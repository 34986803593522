import React from "react";
import { defineMessages, FormattedMessage } from "react-intl";
import Link from "../containers/Link";
import LocaleSelector from "../containers/LocaleSelector";
import { copy as messages } from "../strings/strings";
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { isServer } from "../utils/helpers";

export default class Header extends React.Component {
  render() {
    const prefix = !isServer() ? window.mtp.assetLocation : "";
    return (
      <header className="header">
        <div className="header__wrapper">
          {!this.props.disableMenu && (
            <div className="header__hamburger">
              <ReactCSSTransitionGroup
                transitionName="example"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
              >
                <span className="hamburger" onClick={this.props.onMenuToggle}>
                  MENU <span>a</span>
                </span>
              </ReactCSSTransitionGroup>
              <ReactCSSTransitionGroup
                transitionName="tooltip-transition"
                transitionAppear={true}
                transitionAppearTimeout={500}
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}
              >
                {this.props.showTooltip && (
                  <div
                    className="header__tooltip tooltip"
                    onClick={this.props.onMenuToggle}
                  >
                    <FormattedMessage {...messages["menu-tooltip"]} />
                  </div>
                )}
              </ReactCSSTransitionGroup>
            </div>
          )}
          <h1 className="header__logo">
            <a href="https://insights.fb.com/">
              <img
                className="header__logo-image"
                src={prefix + "/images/facebookIQ-01.svg"}
                alt="Facebook IQ"
              />
            </a>
            <Link to="">
              <span className="header__logo-message">
                <FormattedMessage {...messages.title} />
              </span>
            </Link>
          </h1>
          <div className="header__locale">
            <LocaleSelector />
          </div>
          <div className="header__nav">
            <Link to="about" className="header__nav-item">
              <FormattedMessage {...messages.about} />
            </Link>
          </div>
        </div>
      </header>
    );
  }
}
