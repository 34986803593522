import { navigation } from "../constants/constants";

export function selectParent(level = 0, id = null) {
  return {
    type: navigation.SELECT_PARENT,
    level: level,
    id: id,
  };
}

export function selectChild(slug) {
  return {
    type: navigation.SELECT_CHILD,
    slug: slug,
  };
}

export function open() {
  return {
    type: navigation.OPEN,
  };
}

export function close() {
  return {
    type: navigation.CLOSE,
  };
}

export function toggle() {
  return {
    type: navigation.TOGGLE,
  };
}

export function hasOpened() {
  return {
    type: navigation.HAS_OPENED,
  };
}
