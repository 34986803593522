import { defineMessages } from 'react-intl';
    export const intro = defineMessages({
    "title": {
        "id": "intro-title",
        "defaultMessage": "Meet the Parents",
        "description": "Intro page text"
    },
    "body": {
        "id": "intro-body",
        "defaultMessage": "Body copy",
        "description": "Intro page text"
    },
    "cta": {
        "id": "intro-cta",
        "defaultMessage": "Choose country",
        "description": "Intro page text"
    },
    "heading": {
        "id": "intro-heading",
        "defaultMessage": "The nuclear family is fast becoming the networked family",
        "description": "Intro page text"
    },
    "lede": {
        "id": "intro-lede",
        "defaultMessage": "But how much do we really know about the reality of modern parenting?",
        "description": "Intro page text"
    },
    "discover": {
        "id": "intro-discover",
        "defaultMessage": "What we’ve discovered",
        "description": "Intro page text"
    },
    "discover1": {
        "id": "intro-discover1",
        "defaultMessage": "Facebook IQ commissioned quantitative research, conducted by Ipsos Media CT, in order to understand how tradition and technology have shaped the realities of parenting across generations and geographies.",
        "description": "Intro page text"
    },
    "discover2": {
        "id": "intro-discover2",
        "defaultMessage": "We’ve discovered that while the mission of parenting may be timeless, the journey looks very different than it used to. Today’s parents feel more informed than ever before, while tech-savvy kids are turning ‘pester power’ into the ‘informed factor’. But there’s so much more to learn.",
        "description": "Intro page text"
    },
    "theme-family1": {
        "id": "intro-theme-family1",
        "defaultMessage": "Family life",
        "description": "Intro page text"
    },
    "theme-family2": {
        "id": "intro-theme-family2",
        "defaultMessage": "Modern parenthood means learning to take the rough with the smooth. But we’ve also found that attitudes can be very different depending on the life stage of the child and the generation of the parent.",
        "description": "Intro page text"
    },
    "theme-spending1": {
        "id": "intro-theme-spending1",
        "defaultMessage": "Spending",
        "description": "Intro page text"
    },
    "theme-spending2": {
        "id": "intro-theme-spending2",
        "defaultMessage": "Money is never far from the mind of parents. We learned how and where parents are spending their money and who in the family has the final say on purchases.",
        "description": "Intro page text"
    },
    "theme-tech1": {
        "id": "intro-theme-tech1",
        "defaultMessage": "Technology",
        "description": "Intro page text"
    },
    "theme-tech2": {
        "id": "intro-theme-tech2",
        "defaultMessage": "The digital age has put parents under the spotlight more than ever before. We studied parents’ devices and learned about the role mobile technology plays in the modern family.",
        "description": "Intro page text"
    },
    "footer": {
        "id": "intro-footer",
        "defaultMessage": "-",
        "description": "Intro page text"
    }
});