import { defineMessages } from "react-intl";
export const storyTitles = defineMessages({
  tech1: {
    id: "story-title-tech1",
    defaultMessage: "From nuclear to networked: the devices parents own",
    description: "Story title",
  },
  tech5: {
    id: "story-title-tech5",
    defaultMessage: "Help or hindrance? How do mobile devices impact parents",
    description: "Story title",
  },
  fam1: {
    id: "story-title-fam1",
    defaultMessage:
      "Mind the generation gap: how do modern parents compare to their own parents?",
    description: "Story title",
  },
  fam2: {
    id: "story-title-fam2",
    defaultMessage:
      "Thriving or just getting by? What parents really think of family life",
    description: "Story title",
  },
  fam3: {
    id: "story-title-fam3",
    defaultMessage: "Comfort or convenience: what matters most to your family?",
    description: "Story title",
  },
  fam4a: {
    id: "story-title-fam4a",
    defaultMessage: "Which chores do parents end up doing?",
    description: "Story title",
  },
  fam4b: {
    id: "story-title-fam4b",
    defaultMessage: "Which chores do parents manage to avoid?",
    description: "Story title",
  },
  fam5: {
    id: "story-title-fam5",
    defaultMessage: "What really changes when the kids arrive?",
    description: "Story title",
  },
  con1: {
    id: "story-title-con1",
    defaultMessage: "What do parents spend their money on?",
    description: "Story title",
  },
  con2: {
    id: "story-title-con2",
    defaultMessage: "Where do children influence spending decisions?",
    description: "Story title",
  },
  con3a: {
    id: "story-title-con3a",
    defaultMessage: "Their house, their rules",
    description: "Story title",
  },
  con3b: {
    id: "story-title-con3b",
    defaultMessage: "Who's calling the shots? Where parents get a say",
    description: "Story title",
  },
  con3c: {
    id: "story-title-con3c",
    defaultMessage: "Who's calling the shots? Where partners get a say",
    description: "Story title",
  },
  con3d: {
    id: "story-title-con3d",
    defaultMessage: "Who's calling the shots? Where their kids get a say",
    description: "Story title",
  },
  con3e: {
    id: "story-title-con3e",
    defaultMessage: "Who's calling the shots? Where other kids get a say",
    description: "Story title",
  },
  con3f: {
    id: "story-title-con3f",
    defaultMessage: "Who's calling the shots? Where other adults get a say",
    description: "Story title",
  },
});
