import { navigation as constants } from "../constants/constants";
import { defineMessages } from "react-intl";
import { slugify } from "../utils/helpers";

import config from "../config";

let intitialState = {
  isOpen: false,
  hasOpened: false,
  selected: [],
  items: config.navigation,
};

function findBySlug(items, slug) {
  let selected = [];
  items.forEach((item) => {
    if (item.slug === slug) {
      selected.push(item.id);
    }

    if (item.children) {
      let children = findBySlug(item.children, slug);

      if (children.length > 0) {
        selected.push(item.id);
        selected = selected.concat(children);
      }
    }
  });
  return selected;
}

export default function navigation(state = intitialState, action) {
  switch (action.type) {
    case constants.SELECT_PARENT:
      let selected = state.selected.slice(0, action.level);
      selected.push(action.id);

      return {
        ...state,
        ...{
          selected: selected,
        },
      };
    case constants.SELECT_CHILD:
      return {
        ...state,
        ...{
          selected: findBySlug(state.items, action.slug),
        },
      };
    case constants.OPEN:
      return {
        ...state,
        ...{
          isOpen: true,
          hasOpened: true,
        },
      };
    case constants.CLOSE:
      return {
        ...state,
        ...{
          isOpen: false,
        },
      };
    case constants.TOGGLE:
      return {
        ...state,
        ...{
          isOpen: !state.isOpen,
          hasOpened: true,
        },
      };
    case constants.HAS_OPENED:
      return {
        ...state,
        ...{
          hasOpened: true,
        },
      };
    default:
      return state;
  }
}
