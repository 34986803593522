import { countries as constants } from "../constants/constants";

export function setCountry(country) {
  return {
    type: constants.SET_COUNTRY,
    country,
  };
}

export function menuOpen() {
  return {
    type: constants.OPEN,
  };
}

export function menuClose() {
  return {
    type: constants.CLOSE,
  };
}

export function menuToggle() {
  return {
    type: constants.TOGGLE,
  };
}
