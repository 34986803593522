import { stories } from "../constants/constants";

import australia from "../data/australia.json";
import brazil from "../data/brazil.json";
import canada from "../data/canada.json";
import mexico from "../data/mexico.json";
import spain from "../data/spain.json";
import uk from "../data/uk.json";
import us from "../data/us.json";

const data = {
  australia,
  brazil,
  canada,
  mexico,
  spain,
  uk,
  us,
};

export function setStory(story = null) {
  return {
    type: stories.SELECT_STORY,
    story: story,
  };
}

function requestCountryData(country) {
  return {
    type: stories.REQUEST_COUNTRYDATA,
    country,
  };
}

function receiveCountryData(country, json) {
  return {
    type: stories.RECEIVE_COUNTRYDATA,
    country: country,
    data: json,
    receivedAt: Date.now(),
  };
}

function receiveCountryDataError(country, error) {
  return {
    type: stories.RECEIVE_COUNTRYDATAERROR,
    country: country,
    error: error,
    receivedAt: Date.now(),
  };
}

function fetchCountryData(country, callback) {
  return function (dispatch, getState) {
    dispatch(requestCountryData(country));

    dispatch(receiveCountryData(country, data[country]));

    if (callback) {
      callback();
    }
  };
}

export function maybeFetchCountryData(country, callback) {
  return function (dispatch, getState) {
    const countryData = getState().stories.stories;
    if (
      countryData[1].data === undefined ||
      countryData[1].data[country] === undefined
    ) {
      dispatch(fetchCountryData(country, callback));
    } else {
      if (typeof callback === "function") {
        callback();
      }
    }
  };
}
