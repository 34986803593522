import { connect } from "react-redux";
import { Link } from "react-router";

function mapStateToProps(state) {
    return {
        locale: state.intl.selectedLocale
    };
}

function mapDispatchToProps(dispatch) {
    return {};
}

function mergeProps(stateProps, dispatchProps, ownProps) {
    // let to = stateProps.locale === 'en' ? '/' + ownProps.to : '/' + stateProps.locale + '/' + ownProps.to;
    let to = '/' + stateProps.locale + '/' + ownProps.to;

    return {...ownProps, ...{
        to: to
    }};
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps
)(Link);
