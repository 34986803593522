import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import IntlProvider from "./containers/IntlProvider";
import { Router } from "react-router";
import history from "./utils/history";
import routes from "./routing";
import store from "./store";

import "./styles/main.scss";

// On router update
const routerUpdate = function (handler, state) {
  window.scrollTo(0, 0);

  if (document.querySelector(".page__wrapper")) {
    document.querySelector(".page__wrapper").scrollTop = 0;
  }
};

ReactDOM.render(
  <Provider store={store}>
    <IntlProvider>
      <div className="page-wrapper">
        <Router onUpdate={routerUpdate} routes={routes} history={history} />
      </div>
    </IntlProvider>
  </Provider>,
  document.getElementById("root")
);
