import React from "react";
import { chartTitles, parentStrings } from "../strings/strings";
import GroupSelector from "../containers/GroupSelector";
import StoryChartItem from "../components/StoryChartItem";
import Sources from "../components/Sources";
import { injectIntl, FormattedMessage } from "react-intl";
import DownloadButton from "../containers/DownloadButton";

class StoryChart extends React.Component {
  render() {
    const nationality = this.props.intl.formatMessage(
      parentStrings[this.props.country]
    );
    const story = this.props.story;
    let keys = this.props.keys || [];
    let selectedKey = this.props.selectedKey;
    let parentGroup = this.props.intl.formatMessage(parentStrings["multiple"], {
      nationality: nationality,
    });

    let chartClasses = "story-chart";
    if (story.type) {
      chartClasses += " story-chart--" + story.type;
    }

    if (selectedKey) {
      keys = [selectedKey];
      parentGroup = this.props.intl.formatMessage(parentStrings[selectedKey], {
        nationality: nationality,
      });
    }

    if (this.props.replaceWithMums) {
      parentGroup = parentGroup.replace(/moms/, "mums");
    }

    const hasMultipleKeys = keys.length > 1;
    let chartItemClasses = "story-chart__item";
    if (hasMultipleKeys) {
      chartItemClasses += " story-chart__item--has-keys";
    }

    chartItemClasses += " story-chart-item--keys-" + keys.length;

    const chartItems = story.data[this.props.country].items.map((item, i) => {
      return (
        <div
          key={keys.length === 1 ? i : keys.join("-") + i}
          className={chartItemClasses}
        >
          <StoryChartItem
            id={story.id}
            item={item}
            keys={keys}
            showKeyTitles={hasMultipleKeys}
            viz={story.type}
            replaceWithMums={this.props.replaceWithMums}
          />
        </div>
      );
    });

    return (
      <div className={chartClasses}>
        <h3 className="story-chart__heading">
          <FormattedMessage
            values={{ parent_group: parentGroup }}
            {...chartTitles[story.id]}
          />
          <DownloadButton
            country={this.props.country}
            story={story.slug}
            selectedKey={selectedKey}
          />
        </h3>
        <div className="story-chart__items">{chartItems}</div>

        <div className="story-chart__sources">
          {/* <Sources story={story} keys={keys} country={this.props.country} /> */}
        </div>
      </div>
    );
  }
}

export default injectIntl(StoryChart);
