import React from "react";
import Link from "../containers/Link";
import { navigation as messages } from "../strings/strings";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
import TransitionHeight from "./TransitionHeight";

export default class CountryNavigation extends React.Component {
  render() {
    let countries = this.props.countries;
    let selected = this.props.selected;

    let classes = classnames("country-navigation", {
      "country-navigation--menu-open": this.props.menuOpen,
    });

    return (
      <div className={classes}>
        <div className="country-navigation__header">
          <FormattedMessage {...messages["country"]} />
        </div>
        <button
          className="country-navigation__selected"
          onClick={this.props.toggleMenu}
        >
          <FormattedMessage {...messages[this.props.selected]} />
        </button>
        <TransitionHeight isVisible={this.props.menuOpen}>
          <div className="country-navigation__countries" key="menu">
            {countries.map((country, i) => {
              let classes = classnames("country-navigation__item", {
                "is-active": selected === country.id,
              });
              return (
                <Link
                  tabIndex={this.props.menuOpen ? 0 : -1}
                  key={i}
                  to={"stories/" + country.id}
                  className={classes}
                  onClick={this.props.closeMenu}
                >
                  <FormattedMessage {...messages[country.id]} />
                </Link>
              );
            })}
          </div>
        </TransitionHeight>
      </div>
    );
  }
}
