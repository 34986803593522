import React from "react";
import classnames from "classnames";
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";
import { FormattedMessage } from "react-intl";
import { copy } from "../strings/strings";

export default class BarChart extends React.Component {
  render() {
    let percentage = this.props.percentage;
    let percentageString = this.props.percentage + "%";
    const isHorizontal = this.props.horizontal;
    const includeNumber = !this.props.hideNumber;
    const withMegaNumber = this.props.withMegaNumber;
    const inset = percentage > 50;
    const isNull = isNaN(percentage);

    const chartClasses = classnames(
      "bar-chart",
      { "bar-chart--solid": this.props.solid },
      { "bar-chart--full": percentage == 100 }
    );

    if (isNull) {
      percentage = 0;
      percentageString = <FormattedMessage {...copy["not-available"]} />;
    }

    const inlineStyle = {
      [isHorizontal ? "width" : "height"]: percentage + "%",
    };

    const wrapperClasses = classnames(
      "bar-chart__wrapper",
      { "bar-chart--horizontal": isHorizontal },
      { "bar-chart--has-number": includeNumber },
      { "bar-chart--has-mega-number": withMegaNumber },
      { "bar-chart--tall": this.props.tall },
      { "bar-chart--no-data": isNull }
    );

    return (
      <div className={chartClasses}>
        <ReactCSSTransitionGroup
          transitionName="bar-chart"
          transitionAppear={true}
          transitionAppearTimeout={1100}
          transitionEnterTimeout={100}
          transitionLeaveTimeout={100}
        >
          <div className={wrapperClasses}>
            <span className="bar-chart__percentage" style={inlineStyle}>
              {includeNumber && (
                <span
                  className={classnames("bar-chart__percentage-num", {
                    "bar-chart__percentage-num--inside": inset,
                  })}
                >
                  {percentageString}
                </span>
              )}
            </span>
          </div>
          {withMegaNumber && (
            <span
              className={classnames(
                "bar-chart__percentage-mega-num",
                "mega-number"
              )}
            >
              {percentage}
              <span className="mega-number__percentage">%</span>
            </span>
          )}
        </ReactCSSTransitionGroup>
      </div>
    );
  }
}
