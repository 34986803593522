import React from "react";
import Link from "../containers/Link";
import { copy as countryNames } from "../strings/strings";
import { FormattedMessage } from "react-intl";
import { isServer } from "../utils/helpers";

export default class CountrySelector extends React.Component {
  render() {
    const prefix = !isServer() ? window.mtp.assetLocation : "";

    const countries = this.props.countries.map((country, i) => {
      return (
        <div key={i} className="country-selector__country">
          <Link to={"stories/" + country} className="country-selector__button">
            <FormattedMessage {...countryNames[country]} />
          </Link>
        </div>
      );
    });

    return (
      <div className="country-selector">
        <h3 className="country-selector__title">
          <FormattedMessage {...countryNames["select-country"]} />
          <span className="icon">
            <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 20 25.78">
              <path
                style={{
                  fill: "#ffd600",
                }}
                d="M10,25.78l-0.56-.47C5.93,22.29,0,16.29,0,10a10,10,0,0,1,20,0c0,4.43-3.09,9.43-9.44,15.29ZM10,1.65A8.36,8.36,0,0,0,1.65,10c0,5.33,5,10.62,8.33,13.58,5.56-5.24,8.37-9.81,8.37-13.58A8.36,8.36,0,0,0,10,1.65Z"
              />
              <circle
                style={{
                  fill: "#ffd600",
                  opacity: 0.5,
                }}
                cx="10"
                cy="10.03"
                r="5.26"
              />
            </svg>
          </span>
        </h3>
        <div className="country-selector__countries">{countries}</div>
      </div>
    );
  }
}
