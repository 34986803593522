import { intl as constants } from "../constants/constants";
import { combineReducers } from "redux";

// SELECTED LOCALE
function selectedLocale(state = "en", action) {
  switch (action.type) {
    case constants.SELECT_LOCALE:
      return action.locale;
    default:
      return state;
  }
}

// LOCALE MENU OPEN
function menuOpen(state = false, action) {
  switch (action.type) {
    case constants.OPEN:
      return true;
    case constants.CLOSE:
      return false;
    case constants.TOGGLE:
      return !state;
    default:
      return state;
  }
}

// LOCALE
const localeInitialState = {
  isFetching: false,
  didInvalidate: false,
  messages: {},
  format: {},
};

function locale(state = localeInitialState, action) {
  switch (action.type) {
    case constants.INVALIDATE_LOCALE:
      return {
        ...state,
        ...{
          didInvalidate: true,
        },
      };
    case constants.REQUEST_LOCALE:
      return {
        ...state,
        ...{
          isFetching: true,
          didInvalidate: false,
        },
      };
    case constants.RECEIVE_LOCALE:
      return {
        ...state,
        ...{
          isFetching: false,
          didInvalidate: false,
          messages: action.messages,
          format: action.format,
          lastUpdated: action.receivedAt,
        },
      };
    default:
      return state;
  }
}

// LOCALES
function locales(state = {}, action) {
  switch (action.type) {
    case constants.INVALIDATE_LOCALE:
    case constants.REQUEST_LOCALE:
    case constants.RECEIVE_LOCALE:
      let nextState = {};
      nextState[action.locale] = locale(state[action.locale], action);
      return { ...state, ...nextState };
    default:
      return state;
  }
}

export default combineReducers({
  selectedLocale,
  locales,
  menuOpen,
});
