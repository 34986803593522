import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import intlReducer from './reducers/intl';
import navigation from './reducers/navigation';
import countries from './reducers/countries';
import stories from './reducers/stories';
import groups from './reducers/groups';

import thunkMiddleware from 'redux-thunk';
import history from './utils/history';

const createStoreWithMiddleware = compose(
    applyMiddleware(thunkMiddleware)
)(createStore);

const store = createStoreWithMiddleware(combineReducers({
    navigation: navigation,
    intl: intlReducer,
    countries: countries,
    stories: stories,
    groups: groups
}));

export default store;
