import React from "react";
import classnames from "classnames";
import { parentStrings as messages } from "../strings/strings";
import { FormattedMessage } from "react-intl";

export default class KeySelector extends React.Component {
  render() {
    let keys = this.props.keys;
    let hasOptions = keys.length > 1;
    let allButtonClasses = classnames("keys__item", {
      "is-active": this.props.selectedKey === null,
    });

    if (!hasOptions) {
      return false;
    }

    return (
      <div className="keys">
        <div>
          <h3 className="keys__title">
            <FormattedMessage {...messages["select-" + this.props.selected]} />
          </h3>
          <button
            className={allButtonClasses}
            onClick={this.props.changeGroup.bind(null, this.props.selected)}
          >
            <FormattedMessage {...messages["button-all"]} />
          </button>
          {keys.map((key, i) => {
            let classes = classnames("keys__item", {
              "is-active": key === this.props.selectedKey,
            });

            let message = <FormattedMessage {...messages["button-" + key]} />;
            if (this.props.replaceWithMums && key === "gendermums") {
              message = "Mums";
            }

            return (
              <button
                key={i}
                className={classes}
                onClick={this.props.changeKey.bind(null, key)}
              >
                {message}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}
