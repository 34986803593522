import { connect } from 'react-redux';
import Story from '../components/Story';

function mapStateToProps(state) {
    return {
        stories: state.stories.stories,
        selected: state.stories.selected,
        isFetching: state.stories.isFetching,
        country: state.countries.selected,
        keys: state.groups.selectedKeys,
        selectedKey: state.groups.selectedKey,
        replaceWithMums: state.intl.selectedLocale === 'en' && (state.countries.selected === 'uk' || state.countries.selected === 'australia')
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Story);
