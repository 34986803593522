import { defineMessages } from 'react-intl';
    export const countryIntros = defineMessages({
    "titlecanada": {
        "id": "country-intro--titlecanada",
        "defaultMessage": "Meet the Parents in Canada",
        "description": "Country intro copy"
    },
    "ledecanada": {
        "id": "country-intro--ledecanada",
        "defaultMessage": "How are parents in Canada raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titlecanada": {
        "id": "country-intro--story-1-titlecanada",
        "defaultMessage": "Family network",
        "description": "Country intro copy"
    },
    "story-1-textcanada": {
        "id": "country-intro--story-1-textcanada",
        "defaultMessage": "60% of parents of teens in Canada say mobile devices help them stay connected to their children.",
        "description": "Country intro copy"
    },
    "story-1-valuecanada": {
        "id": "country-intro--story-1-valuecanada",
        "defaultMessage": "60",
        "description": "Country intro copy"
    },
    "story-1-categorycanada": {
        "id": "country-intro--story-1-categorycanada",
        "defaultMessage": "tech",
        "description": "Country intro copy"
    },
    "story-1-typecanada": {
        "id": "country-intro--story-1-typecanada",
        "defaultMessage": "1",
        "description": "Country intro copy"
    },
    "story-1-linkcanada": {
        "id": "country-intro--story-1-linkcanada",
        "defaultMessage": "mobile-parenting",
        "description": "Country intro copy"
    },
    "titleuk": {
        "id": "country-intro--titleuk",
        "defaultMessage": "Meet the Parents in the UK",
        "description": "Country intro copy"
    },
    "ledeuk": {
        "id": "country-intro--ledeuk",
        "defaultMessage": "How are parents in the UK raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titleuk": {
        "id": "country-intro--story-1-titleuk",
        "defaultMessage": "Fatherhood is mobilehood",
        "description": "Country intro copy"
    },
    "story-1-textuk": {
        "id": "country-intro--story-1-textuk",
        "defaultMessage": "98% of dads in the UK own a smartphone.",
        "description": "Country intro copy"
    },
    "story-1-valueuk": {
        "id": "country-intro--story-1-valueuk",
        "defaultMessage": "98",
        "description": "Country intro copy"
    },
    "story-1-categoryuk": {
        "id": "country-intro--story-1-categoryuk",
        "defaultMessage": "tech",
        "description": "Country intro copy"
    },
    "story-1-typeuk": {
        "id": "country-intro--story-1-typeuk",
        "defaultMessage": "1",
        "description": "Country intro copy"
    },
    "story-1-linkuk": {
        "id": "country-intro--story-1-linkuk",
        "defaultMessage": "devices-owned",
        "description": "Country intro copy"
    },
    "titleaustralia": {
        "id": "country-intro--titleaustralia",
        "defaultMessage": "Meet the Parents in Australia",
        "description": "Country intro copy"
    },
    "ledeaustralia": {
        "id": "country-intro--ledeaustralia",
        "defaultMessage": "How are parents in Australia raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titleaustralia": {
        "id": "country-intro--story-1-titleaustralia",
        "defaultMessage": "Seat at the table",
        "description": "Country intro copy"
    },
    "story-1-textaustralia": {
        "id": "country-intro--story-1-textaustralia",
        "defaultMessage": "70% of Boomer parents in Australia say their kids play a role in household decisions about quick service restaurants.",
        "description": "Country intro copy"
    },
    "story-1-valueaustralia": {
        "id": "country-intro--story-1-valueaustralia",
        "defaultMessage": "70",
        "description": "Country intro copy"
    },
    "story-1-categoryaustralia": {
        "id": "country-intro--story-1-categoryaustralia",
        "defaultMessage": "consumer",
        "description": "Country intro copy"
    },
    "story-1-typeaustralia": {
        "id": "country-intro--story-1-typeaustralia",
        "defaultMessage": "2",
        "description": "Country intro copy"
    },
    "story-1-linkaustralia": {
        "id": "country-intro--story-1-linkaustralia",
        "defaultMessage": "decision-makers-kids",
        "description": "Country intro copy"
    },
    "titleus": {
        "id": "country-intro--titleus",
        "defaultMessage": "Meet the Parents in the US",
        "description": "Country intro copy"
    },
    "ledeus": {
        "id": "country-intro--ledeus",
        "defaultMessage": "How are parents in the US raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titleus": {
        "id": "country-intro--story-1-titleus",
        "defaultMessage": "Pester power",
        "description": "Country intro copy"
    },
    "story-1-textus": {
        "id": "country-intro--story-1-textus",
        "defaultMessage": "48% of parents in the US say their child has more impact on purchase decisions than they did growing up.",
        "description": "Country intro copy"
    },
    "story-1-valueus": {
        "id": "country-intro--story-1-valueus",
        "defaultMessage": "48",
        "description": "Country intro copy"
    },
    "story-1-categoryus": {
        "id": "country-intro--story-1-categoryus",
        "defaultMessage": "family",
        "description": "Country intro copy"
    },
    "story-1-typeus": {
        "id": "country-intro--story-1-typeus",
        "defaultMessage": "4",
        "description": "Country intro copy"
    },
    "story-1-linkus": {
        "id": "country-intro--story-1-linkus",
        "defaultMessage": "now-vs-then",
        "description": "Country intro copy"
    },
    "titlebrazil": {
        "id": "country-intro--titlebrazil",
        "defaultMessage": "Meet the Parents in Brazil",
        "description": "Country intro copy"
    },
    "ledebrazil": {
        "id": "country-intro--ledebrazil",
        "defaultMessage": "How are parents in Brazil raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titlebrazil": {
        "id": "country-intro--story-1-titlebrazil",
        "defaultMessage": "Fatherhood is mobilehood",
        "description": "Country intro copy"
    },
    "story-1-textbrazil": {
        "id": "country-intro--story-1-textbrazil",
        "defaultMessage": "95% of dads in Brazil own a smartphone.",
        "description": "Country intro copy"
    },
    "story-1-valuebrazil": {
        "id": "country-intro--story-1-valuebrazil",
        "defaultMessage": "95",
        "description": "Country intro copy"
    },
    "story-1-categorybrazil": {
        "id": "country-intro--story-1-categorybrazil",
        "defaultMessage": "tech",
        "description": "Country intro copy"
    },
    "story-1-typebrazil": {
        "id": "country-intro--story-1-typebrazil",
        "defaultMessage": "3",
        "description": "Country intro copy"
    },
    "story-1-linkbrazil": {
        "id": "country-intro--story-1-linkbrazil",
        "defaultMessage": "devices-owned",
        "description": "Country intro copy"
    },
    "titlespain": {
        "id": "country-intro--titlespain",
        "defaultMessage": "Meet the Parents in Spain",
        "description": "Country intro copy"
    },
    "ledespain": {
        "id": "country-intro--ledespain",
        "defaultMessage": "How are parents in Spain raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titlespain": {
        "id": "country-intro--story-1-titlespain",
        "defaultMessage": "Family planning",
        "description": "Country intro copy"
    },
    "story-1-textspain": {
        "id": "country-intro--story-1-textspain",
        "defaultMessage": "46% of moms in Spain say they plan more family activities than their spouse or partner.",
        "description": "Country intro copy"
    },
    "story-1-valuespain": {
        "id": "country-intro--story-1-valuespain",
        "defaultMessage": "46",
        "description": "Country intro copy"
    },
    "story-1-categoryspain": {
        "id": "country-intro--story-1-categoryspain",
        "defaultMessage": "family",
        "description": "Country intro copy"
    },
    "story-1-typespain": {
        "id": "country-intro--story-1-typespain",
        "defaultMessage": "1",
        "description": "Country intro copy"
    },
    "story-1-linkspain": {
        "id": "country-intro--story-1-linkspain",
        "defaultMessage": "chore-war-i-do",
        "description": "Country intro copy"
    },
    "titlemexico": {
        "id": "country-intro--titlemexico",
        "defaultMessage": "Meet the Parents in Mexico",
        "description": "Country intro copy"
    },
    "ledemexico": {
        "id": "country-intro--ledemexico",
        "defaultMessage": "How are parents in Mexico raising their children in a world that has been reshaped by mobile technology?",
        "description": "Country intro copy"
    },
    "story-1-titlemexico": {
        "id": "country-intro--story-1-titlemexico",
        "defaultMessage": "Fatherhood is mobilehood",
        "description": "Country intro copy"
    },
    "story-1-textmexico": {
        "id": "country-intro--story-1-textmexico",
        "defaultMessage": "95% of dads in Mexico own a smartphone.",
        "description": "Country intro copy"
    },
    "story-1-valuemexico": {
        "id": "country-intro--story-1-valuemexico",
        "defaultMessage": "95",
        "description": "Country intro copy"
    },
    "story-1-categorymexico": {
        "id": "country-intro--story-1-categorymexico",
        "defaultMessage": "tech",
        "description": "Country intro copy"
    },
    "story-1-typemexico": {
        "id": "country-intro--story-1-typemexico",
        "defaultMessage": "2",
        "description": "Country intro copy"
    },
    "story-1-linkmexico": {
        "id": "country-intro--story-1-linkmexico",
        "defaultMessage": "devices-owned",
        "description": "Country intro copy"
    }
});