import { connect } from "react-redux";
import GroupSelector from "../components/GroupSelector";
import { selectGroup } from "../actions/groups";

function mapStateToProps(state) {
  return {
    options: state.groups.options,
    selected: state.groups.selected,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeGroup: (key) => {
      dispatch(selectGroup(key));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(GroupSelector);
