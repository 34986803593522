import { connect } from 'react-redux';
import CountryNavigation from '../components/CountryNavigation';
import history from '../utils/history';
import { menuToggle, menuClose } from '../actions/countries';

function mapStateToProps(state) {
    return {
        countries: state.countries.countries,
        selected: state.countries.selected,
        menuOpen: state.countries.menuOpen
    }
}

function mapDispatchToProps(dispatch) {
    return {
        toggleMenu: () => { dispatch(menuToggle()); },
        closeMenu: () => { dispatch(menuClose()); }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CountryNavigation);