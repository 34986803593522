import React from "react";
import Link from "../containers/Link";
import { countryIntros, copy } from "../strings/strings";
import { FormattedMessage, injectIntl } from "react-intl";
import BarChart from "../components/BarChart";
import Donut from "../components/Donut";
import times from "lodash/times";
import Footer from "../components/Footer";
import { isServer } from "../utils/helpers";

class CountryPage extends React.Component {
  render() {
    const country = this.props.params.country;
    let storyData = [];

    times(3, (i) => {
      const j = i + 1;
      if (
        countryIntros["story-" + j + "-type" + country] &&
        countryIntros["story-" + j + "-value" + country]
      ) {
        const percentage = +this.props.intl.formatMessage(
          countryIntros["story-" + j + "-value" + country]
        );
        storyData.push({
          percentage: percentage,
          category:
            countryIntros["story-" + j + "-category" + country].defaultMessage,
          type: +countryIntros["story-" + j + "-type" + country].defaultMessage,
          link: countryIntros["story-" + j + "-link" + country].defaultMessage,
        });
      }
    });

    const prefix = !isServer() ? window.mtp.assetLocation : "";

    const stories = storyData.map((story, i) => {
      const j = i + 1;
      return (
        <div key={i} className="gi">
          <div
            className={
              "page-content__story page-content__story--" + story.category
            }
          >
            <div className="page-content__story-copy">
              <h3>
                <FormattedMessage
                  {...countryIntros["story-" + j + "-title" + country]}
                />
              </h3>
              <p>
                <FormattedMessage
                  {...countryIntros["story-" + j + "-text" + country]}
                />
              </p>
              <p>
                <Link
                  to={"stories/" + country + "/" + story.link}
                  className="button"
                >
                  <FormattedMessage {...copy["full-story"]} />
                  <span className="button__icon">
                    <svg
                      id="Layer_1"
                      data-name="Layer 1"
                      viewBox="0 0 15.54 14.71"
                    >
                      <path
                        style={{ fill: "#ffd600" }}
                        d="M15.54,7.33a1.29,1.29,0,0,1-1.29,1.29h-13A1.29,1.29,0,0,1,1.29,6h9.85L7.3,2.2A1.29,1.29,0,0,1,9.13.38l6,6A1.29,1.29,0,0,1,15.54,7.33Z"
                      />
                      <path
                        style={{ fill: "#ffd600" }}
                        d="M15.54,7.33a1.29,1.29,0,0,1-.36.89L9.32,14.32a1.29,1.29,0,0,1-1.86-1.79l5.87-6.09A1.29,1.29,0,0,1,15.54,7.33Z"
                      />
                    </svg>
                  </span>
                </Link>
              </p>
            </div>
            <div className="page-content__chart page-content__story-chart">
              {story.type === 1 && <BarChart percentage={story.percentage} />}
              {story.type === 2 && (
                <BarChart
                  percentage={story.percentage}
                  horizontal
                  hideNumber
                  withMegaNumber
                  tall
                />
              )}
              {story.type === 3 && (
                <BarChart
                  percentage={story.percentage}
                  hideNumber
                  withMegaNumber
                  solid
                />
              )}
              {story.type === 4 && <Donut percentage={story.percentage} />}
            </div>
          </div>
        </div>
      );
    });

    return (
      <div className="page-content">
        <div className="page-content__header page-content__header--with-icons">
          <h1 className="">
            <FormattedMessage {...countryIntros["title" + country]} />
          </h1>
        </div>

        <div className="page-content__body">
          <div className="g">
            <p className="page-content__lede gi">
              <FormattedMessage {...countryIntros["lede" + country]} />
            </p>
            <div className="gi">
              <hr />
            </div>
            {stories}
          </div>
        </div>

        <div className="page-content__footer page-content__footer--tight">
          <div className="g">
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(CountryPage);
