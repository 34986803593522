import React from "react";
import Link from "../containers/Link";
import Story from "../containers/Story";
import Footer from "../components/Footer";
import { defineMessages, FormattedMessage } from "react-intl";

export default class StoryPage extends React.Component {
  render() {
    const story = this.props.params.slug;
    return (
      <div>
        <Story />
        <div className="page-content__footer page-content__footer--tight">
          <div className="g">
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
