import { defineMessages } from "react-intl";
export const chartTitles = defineMessages({
  tech1: {
    id: "chart-title-tech1",
    defaultMessage:
      "Percentage of {parent_group} who own one or more of device",
    description: "Chart title",
  },
  tech5: {
    id: "chart-title-tech5",
    defaultMessage:
      "Percentage of {parent_group} who agree mobile devices help with the following",
    description: "Chart title",
  },
  fam1: {
    id: "chart-title-fam1",
    defaultMessage: "Percentage of {parent_group} who agree",
    description: "Chart title",
  },
  fam2: {
    id: "chart-title-fam2",
    defaultMessage:
      "Percentage of {parent_group} who describe their family as this",
    description: "Chart title",
  },
  fam3: {
    id: "chart-title-fam3",
    defaultMessage:
      "Percentage of {parent_group} who say this best describes their family",
    description: "Chart title",
  },
  fam4a: {
    id: "chart-title-fam4a",
    defaultMessage:
      "Percentage of {parent_group} who say they do more of the following than their partner",
    description: "Chart title",
  },
  fam4b: {
    id: "chart-title-fam4b",
    defaultMessage:
      "Percentage of {parent_group} who say their partner does more of the following than they do",
    description: "Chart title",
  },
  fam5: {
    id: "chart-title-fam5",
    defaultMessage:
      "Percentage of {parent_group} who agree having kids changes the following",
    description: "Chart title",
  },
  con1: {
    id: "chart-title-con1",
    defaultMessage:
      "Percentage of {parent_group} who say their family uses or purchases items or services in the following categories",
    description: "Chart title",
  },
  con2: {
    id: "chart-title-con2",
    defaultMessage:
      "Percentage of {parent_group} who say their child contributes to decisions about the following",
    description: "Chart title",
  },
  con3a: {
    id: "chart-title-con3a",
    defaultMessage:
      "Percentage of {parent_group} who say they alone play a role in household decisions on the following",
    description: "Chart title",
  },
  con3b: {
    id: "chart-title-con3b",
    defaultMessage:
      "Percentage of {parent_group} who say they play a role in household decisions on the following",
    description: "Chart title",
  },
  con3c: {
    id: "chart-title-con3c",
    defaultMessage:
      "Percentage of {parent_group} who say their partner plays a role in household decisions on the following",
    description: "Chart title",
  },
  con3d: {
    id: "chart-title-con3d",
    defaultMessage:
      "Percentage of {parent_group} who say their kids play a role in household decisions on the following",
    description: "Chart title",
  },
  con3e: {
    id: "chart-title-con3e",
    defaultMessage:
      "Percentage of {parent_group} who say other kids play a role in household decisions on the following",
    description: "Chart title",
  },
  con3f: {
    id: "chart-title-con3f",
    defaultMessage:
      "Percentage of {parent_group} who say other adults play a role in household decisions on the following",
    description: "Chart title",
  },
});
