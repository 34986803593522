import { connect } from 'react-redux';
import DownloadButton from '../components/DownloadButton';

function mapStateToProps(state, props) {

    return {...props, ...{
        language: state.intl.selectedLocale,
        group: state.groups.selected
    }};
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DownloadButton);
