
export function slugify(name) {
    return name.toLowerCase()
        .replace(/ /g,'-')
        .replace(/[^\w-]+/g,'');
}

export function isServer() {
   return ! (typeof window != 'undefined' && window.document);
}
