import { defineMessages } from 'react-intl';
    export const about = defineMessages({
    "title": {
        "id": "about-title",
        "defaultMessage": "Meet the Parents",
        "description": "About page text"
    },
    "sub-title": {
        "id": "about-sub-title",
        "defaultMessage": "Facebook Global Parents Survey ",
        "description": "About page text"
    },
    "sub-title-small": {
        "id": "about-sub-title-small",
        "defaultMessage": "by Ipsos Media CT, March–April 2015",
        "description": "About page text"
    },
    "meth-header": {
        "id": "about-meth-header",
        "defaultMessage": "Methodology",
        "description": "About page text"
    },
    "meth-para1": {
        "id": "about-meth-para1",
        "defaultMessage": "Facebook IQ commissioned Ipsos Media CT to carry out an online study of self-identified parents aged 25–65 in seven markets: Australia, Brazil, Canada, Mexico, Spain, UK and US. The study was conducted with 1,000 respondents per market.",
        "description": "About page text"
    },
    "meth-para2": {
        "id": "about-meth-para2",
        "defaultMessage": "Quotas were set for the following parent groups: \"New parents\", \"Parents of young children\", \"Parents of schoolchildren\" and \"Parents of teens.\" Within these parent groups, quotas were set to 50% mums and 50% dads.",
        "description": "About page text"
    },
    "meth-para3": {
        "id": "about-meth-para3",
        "defaultMessage": "We have applied a minimum base size of 100.",
        "description": "About page text"
    },
    "parent-header": {
        "id": "about-parent-header",
        "defaultMessage": "Parent groups",
        "description": "About page text"
    },
    "parent-para1": {
        "id": "about-parent-para1",
        "defaultMessage": "With this tool it is possible to compare the responses of parents according to their gender, generation and stage of parenting.",
        "description": "About page text"
    },
    "parent-para2": {
        "id": "about-parent-para2",
        "defaultMessage": "Parent generation groups have been defined as follows.",
        "description": "About page text"
    },
    "parent-geny-header": {
        "id": "about-parent-geny-header",
        "defaultMessage": "Millennials",
        "description": "About page text"
    },
    "parent-geny-text": {
        "id": "about-parent-geny-text",
        "defaultMessage": "Parents between 25 and 34 years old.",
        "description": "About page text"
    },
    "parent-genx-header": {
        "id": "about-parent-genx-header",
        "defaultMessage": "Generation X",
        "description": "About page text"
    },
    "parent-genx-text": {
        "id": "about-parent-genx-text",
        "defaultMessage": "Parents between 35 and 49 years old.",
        "description": "About page text"
    },
    "parent-boomer-header": {
        "id": "about-parent-boomer-header",
        "defaultMessage": "Boomers",
        "description": "About page text"
    },
    "parent-boomer-text": {
        "id": "about-parent-boomer-text",
        "defaultMessage": "Parents between 50 and 65 years old.",
        "description": "About page text"
    },
    "parent-para3": {
        "id": "about-parent-para3",
        "defaultMessage": "Stage of parenting groups have been defined as follows.",
        "description": "About page text"
    },
    "parent-new-header": {
        "id": "about-parent-new-header",
        "defaultMessage": "New parents",
        "description": "About page text"
    },
    "parent-new-text": {
        "id": "about-parent-new-text",
        "defaultMessage": "Expecting parents and parents with a child less than 1 year old.",
        "description": "About page text"
    },
    "parent-young-header": {
        "id": "about-parent-young-header",
        "defaultMessage": "Parents of young children",
        "description": "About page text"
    },
    "parent-young-text": {
        "id": "about-parent-young-text",
        "defaultMessage": "Parents with a child between 1 and 5 years old.",
        "description": "About page text"
    },
    "parent-school-header": {
        "id": "about-parent-school-header",
        "defaultMessage": "Parents of schoolchildren",
        "description": "About page text"
    },
    "parent-school-text": {
        "id": "about-parent-school-text",
        "defaultMessage": "Parents with a child between 6 and 12 years old.",
        "description": "About page text"
    },
    "parent-teen-header": {
        "id": "about-parent-teen-header",
        "defaultMessage": "Parents of teens",
        "description": "About page text"
    },
    "parent-teen-text": {
        "id": "about-parent-teen-text",
        "defaultMessage": "Parents with a child between 13 and 17 years old.",
        "description": "About page text"
    },
    "extra-header": {
        "id": "about-extra-header",
        "defaultMessage": "Find out more",
        "description": "About page text"
    },
    "extra-para1": {
        "id": "about-extra-para1",
        "defaultMessage": "Find out <a href=\"http://insights.fb.com/tag/meet-the-parents/\">more about the study and its insights</a> into modern parenting.",
        "description": "About page text"
    },
    "extra-link-text": {
        "id": "about-extra-link-text",
        "defaultMessage": "Find out more",
        "description": "About page text"
    }
});