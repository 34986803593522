import { connect } from "react-redux";
import LocaleSelector from "../components/LocaleSelector";
import { switchLocale, toggle, close } from "../actions/intl";

function mapStateToProps(state) {
    return {
        locale: state.intl.selectedLocale,
        url: state.routing,
        menuOpen: state.intl.menuOpen
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changeLocale: (locale) => {
            dispatch(switchLocale(locale));
        },
        toggleMenu: () => {
            dispatch(toggle())
        },
        hideMenu: () => {
            dispatch(close())
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocaleSelector);
