import { intl as constants } from "../constants/constants";
import { isServer } from "../utils/helpers";

import en from "../lang/en.json";
import es from "../lang/es.json";
import mx from "../lang/mx.json";
import pt from "../lang/pt.json";

const locales = {
  en,
  es,
  mx,
  pt,
};

export function selectLocale(locale) {
  if (!isServer()) {
    const html = document.querySelector("html");
    html.setAttribute("lang", locale);
  }

  return {
    type: constants.SELECT_LOCALE,
    locale,
  };
}

function requestLocale(locale) {
  return {
    type: constants.REQUEST_LOCALE,
    locale,
  };
}

function receiveLocale(locale, json) {
  return {
    type: constants.RECEIVE_LOCALE,
    locale: locale,
    messages: json,
    format: "en",
    receivedAt: Date.now(),
  };
}

export function switchLocale(locale, callback) {
  return function (dispatch) {
    dispatch(selectLocale(locale));
    dispatch(requestLocale(locale));

    dispatch(receiveLocale(locale, locales[locale]));

    if (callback) {
      callback();
    }
  };
}

export function open() {
  return {
    type: constants.OPEN,
  };
}

export function close() {
  return {
    type: constants.CLOSE,
  };
}

export function toggle() {
  return {
    type: constants.TOGGLE,
  };
}
