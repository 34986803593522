import React from "react";
import { copy } from "../strings/strings";
import { FormattedHTMLMessage } from "react-intl";
import { Link } from "react-router";
import ExternalLink from "../components/ExternalLink";

export default class Footer extends React.Component {
  render() {
    const year = new Date().getFullYear();
    return (
      <div className="page-footer">
        <ExternalLink href="https://insights.fb.com/">
          <FormattedHTMLMessage {...copy["back-to-link"]} />
        </ExternalLink>
        <FormattedHTMLMessage {...copy["copyright"]} values={{ year: year }} />
      </div>
    );
  }
}
