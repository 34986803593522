import { groups } from "../constants/constants";

export function selectGroup(key) {
  return {
    type: groups.SELECT_GROUP,
    key: key,
  };
}

export function selectKey(key) {
  return {
    type: groups.SELECT_KEY,
    key: key,
  };
}
