import React from "react";
import Link from "../containers/Link";
import { navigation as messages } from "../strings/strings";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";
// import {VelocityComponent} from 'velocity-react';
import find from "lodash/find";
import TransitionHeight from "./TransitionHeight";
import { isServer } from "../utils/helpers";

export default class Navigation extends React.Component {
  render() {
    let items = this.props.items;
    let selected = this.props.selected;
    let stories = this.props.stories;

    function renderLevel(items, level, isSelected, parent = null) {
      let renderItems = items.map((item, i) => {
        let isActive = item.id === selected[level];
        let hasChildren = item.children;
        let category = find(stories, { id: item.id }).category;
        let classes = classnames(
          "menu__item",
          "menu__item--item-" + category,
          { "is-active": isActive },
          { "has-children": hasChildren }
        );

        let onClick;
        if (hasChildren) {
          onClick = () => {
            if (isActive && isSelected) {
              this.props.onParentSelect.call(null, level, parent);
            } else {
              this.props.onParentSelect.call(null, level, item.id);
            }
          };
        } else {
          onClick = this.props.onItemSelect.bind(null, item, level, item.id);
        }

        let linkItem = item.slug ? (
          <Link
            tabIndex={isSelected ? 0 : -1}
            className={classes}
            to={"stories/" + this.props.country + "/" + item.slug}
          >
            <FormattedMessage {...messages[item.id]} />
          </Link>
        ) : (
          <button
            tabIndex={isSelected ? 0 : -1}
            className={classes}
            onClick={onClick}
          >
            <FormattedMessage {...messages[item.id]} />
          </button>
        );

        return [
          linkItem,
          hasChildren &&
            renderLevel.call(this, item.children, level + 1, isActive, item.id),
        ];
      });

      const prefix = !isServer() ? window.mtp.assetLocation : "";
      return (
        <TransitionHeight isVisible={isSelected}>
          <div
            key={level}
            className={"menu__level menu__level--level-" + level}
          >
            {renderItems}
            {level === 1 && (
              <svg width="225" height="20" viewBox="0 0 225 20" version="1.1">
                <defs>
                  <linearGradient id="MyGradient" x1="0" x2="0" y1="0" y2="1">
                    <stop offset="5%" stopColor="#30333c" stopOpacity="0" />
                    <stop offset="95%" stopColor="#30333c" />
                  </linearGradient>
                </defs>

                <rect
                  fill="url(#MyGradient)"
                  x="0"
                  y="0"
                  width="100%"
                  height="100%"
                />
              </svg>
            )}
          </div>
        </TransitionHeight>
      );
    }

    return <nav className="menu">{renderLevel.call(this, items, 0, true)}</nav>;
  }
}
