import { defineMessages } from 'react-intl';
    export const items = defineMessages({
    "item-they-spend-more-time-working": {
        "id": "item-they-spend-more-time-working",
        "defaultMessage": "They spend more time working",
        "description": "Item name"
    },
    "item-they-spend-more-time-with-their-child": {
        "id": "item-they-spend-more-time-with-their-child",
        "defaultMessage": "They spend more time with their child",
        "description": "Item name"
    },
    "item-they-listen-to-their-child-more": {
        "id": "item-they-listen-to-their-child-more",
        "defaultMessage": "They listen to their child more",
        "description": "Item name"
    },
    "item-they-are-more-connected-with-their-child": {
        "id": "item-they-are-more-connected-with-their-child",
        "defaultMessage": "They are more connected with their child",
        "description": "Item name"
    },
    "item-they-are-more-connected-with-their-childs-friends": {
        "id": "item-they-are-more-connected-with-their-childs-friends",
        "defaultMessage": "They are more connected with their child's friends",
        "description": "Item name"
    },
    "item-they-are-more-involved-with-their-childs-activities": {
        "id": "item-they-are-more-involved-with-their-childs-activities",
        "defaultMessage": "They are more involved with their child's activities",
        "description": "Item name"
    },
    "item-they-are-more-concerned-about-their-childs-future": {
        "id": "item-they-are-more-concerned-about-their-childs-future",
        "defaultMessage": "They are more concerned about their child's future",
        "description": "Item name"
    },
    "item-they-are-better-able-to-help-their-child-stay-connected-with-the-extended-family": {
        "id": "item-they-are-better-able-to-help-their-child-stay-connected-with-the-extended-family",
        "defaultMessage": "They are better able to help their child stay connected with the extended family",
        "description": "Item name"
    },
    "item-their-child-has-more-impact-on-purchase-decisions": {
        "id": "item-their-child-has-more-impact-on-purchase-decisions",
        "defaultMessage": "Their child has more impact on purchase decisions",
        "description": "Item name"
    },
    "item-they-purchase-more-child-specific-products": {
        "id": "item-they-purchase-more-child-specific-products",
        "defaultMessage": "They purchase more child-specific products",
        "description": "Item name"
    },
    "item-they-have-more-access-to-information": {
        "id": "item-they-have-more-access-to-information",
        "defaultMessage": "They have more access to information",
        "description": "Item name"
    },
    "item-they-are-more-informed": {
        "id": "item-they-are-more-informed",
        "defaultMessage": "They are more informed",
        "description": "Item name"
    },
    "item-computer": {
        "id": "item-computer",
        "defaultMessage": "Computer",
        "description": "Item name"
    },
    "item-smartphone": {
        "id": "item-smartphone",
        "defaultMessage": "Smartphone",
        "description": "Item name"
    },
    "item-tablet": {
        "id": "item-tablet",
        "defaultMessage": "Tablet",
        "description": "Item name"
    },
    "item-wearable": {
        "id": "item-wearable",
        "defaultMessage": "Wearable",
        "description": "Item name"
    },
    "item-digital-media-receiver": {
        "id": "item-digital-media-receiver",
        "defaultMessage": "Digital media receiver",
        "description": "Item name"
    },
    "item-gaming-console": {
        "id": "item-gaming-console",
        "defaultMessage": "Gaming console",
        "description": "Item name"
    },
    "item-mobile-phone-without-internet": {
        "id": "item-mobile-phone-without-internet",
        "defaultMessage": "Mobile phone without Internet",
        "description": "Item name"
    },
    "item-managing-their-family-on-a-daily-basis": {
        "id": "item-managing-their-family-on-a-daily-basis",
        "defaultMessage": "Managing their family on a daily basis",
        "description": "Item name"
    },
    "item-being-a-more-productive-parent": {
        "id": "item-being-a-more-productive-parent",
        "defaultMessage": "Being a more productive parent",
        "description": "Item name"
    },
    "item-being-a-more-empowered-parent": {
        "id": "item-being-a-more-empowered-parent",
        "defaultMessage": "Being a more empowered parent",
        "description": "Item name"
    },
    "item-keeping-the-kids-entertained": {
        "id": "item-keeping-the-kids-entertained",
        "defaultMessage": "Keeping the kids entertained",
        "description": "Item name"
    },
    "item-staying-connected-with-their-kids": {
        "id": "item-staying-connected-with-their-kids",
        "defaultMessage": "Staying connected with their kids",
        "description": "Item name"
    },
    "item-keeping-in-the-loop-on-what-their-kids-like": {
        "id": "item-keeping-in-the-loop-on-what-their-kids-like",
        "defaultMessage": "Keeping in the loop on what their kids like",
        "description": "Item name"
    },
    "item-staying-connected-with-other-family-members": {
        "id": "item-staying-connected-with-other-family-members",
        "defaultMessage": "Staying connected with other family members",
        "description": "Item name"
    },
    "item-being-a-better-consumer-when-making-family-purchases": {
        "id": "item-being-a-better-consumer-when-making-family-purchases",
        "defaultMessage": "Being a better consumer when making family purchases",
        "description": "Item name"
    },
    "item-they-dont---they-get-in-the-way-of-quality-time-with-the-family": {
        "id": "item-they-dont---they-get-in-the-way-of-quality-time-with-the-family",
        "defaultMessage": "They don't - they get in the way of quality time with the family",
        "description": "Item name"
    },
    "item-travel": {
        "id": "item-travel",
        "defaultMessage": "Travel",
        "description": "Item name"
    },
    "item-financial-services-and-investments": {
        "id": "item-financial-services-and-investments",
        "defaultMessage": "Financial services and investments",
        "description": "Item name"
    },
    "item-motor-vehicles": {
        "id": "item-motor-vehicles",
        "defaultMessage": "Motor vehicles",
        "description": "Item name"
    },
    "item-smartphones-and-wireless-services": {
        "id": "item-smartphones-and-wireless-services",
        "defaultMessage": "Smartphones and wireless services",
        "description": "Item name"
    },
    "item-personal-and-beauty-care": {
        "id": "item-personal-and-beauty-care",
        "defaultMessage": "Personal and beauty care",
        "description": "Item name"
    },
    "item-packaged-food": {
        "id": "item-packaged-food",
        "defaultMessage": "Packaged food",
        "description": "Item name"
    },
    "item-non-alcoholic-drinks": {
        "id": "item-non-alcoholic-drinks",
        "defaultMessage": "Non-alcoholic drinks",
        "description": "Item name"
    },
    "item-quick-service-restaurants": {
        "id": "item-quick-service-restaurants",
        "defaultMessage": "Quick service restaurants",
        "description": "Item name"
    },
    "item-big-box-retail": {
        "id": "item-big-box-retail",
        "defaultMessage": "Big-box retail",
        "description": "Item name"
    },
    "item-department-stores": {
        "id": "item-department-stores",
        "defaultMessage": "Department stores",
        "description": "Item name"
    },
    "item-online-shopping": {
        "id": "item-online-shopping",
        "defaultMessage": "Online shopping",
        "description": "Item name"
    },
    "item-cooking": {
        "id": "item-cooking",
        "defaultMessage": "Cooking",
        "description": "Item name"
    },
    "item-cleaning": {
        "id": "item-cleaning",
        "defaultMessage": "Cleaning",
        "description": "Item name"
    },
    "item-grocery-shopping": {
        "id": "item-grocery-shopping",
        "defaultMessage": "Grocery shopping",
        "description": "Item name"
    },
    "item-taking-care-of-children": {
        "id": "item-taking-care-of-children",
        "defaultMessage": "Taking care of children",
        "description": "Item name"
    },
    "item-planning-family-activities": {
        "id": "item-planning-family-activities",
        "defaultMessage": "Planning family activities",
        "description": "Item name"
    },
    "item-managing-household-finances": {
        "id": "item-managing-household-finances",
        "defaultMessage": "Managing household finances",
        "description": "Item name"
    },
    "item-making-household-repairs": {
        "id": "item-making-household-repairs",
        "defaultMessage": "Making household repairs",
        "description": "Item name"
    },
    "item-comfort": {
        "id": "item-comfort",
        "defaultMessage": "Comfort",
        "description": "Item name"
    },
    "item-comfort-description": {
        "id": "item-comfort-description",
        "defaultMessage": "\"My family loves to relax and enjoy themselves. There’s no 'roughing it' for us—comfort is the name of the game.\"",
        "description": "Item description"
    },
    "item-convenience": {
        "id": "item-convenience",
        "defaultMessage": "Convenience",
        "description": "Item name"
    },
    "item-convenience-description": {
        "id": "item-convenience-description",
        "defaultMessage": "\"We are always on the go—convenience is key. We are always looking for ways to make the most of our time.\"",
        "description": "Item description"
    },
    "item-wellbeing": {
        "id": "item-wellbeing",
        "defaultMessage": "Wellbeing",
        "description": "Item name"
    },
    "item-wellbeing-description": {
        "id": "item-wellbeing-description",
        "defaultMessage": "\"My family is at its best when I am at my best. I make sure I take care of myself so I can take care of my family.\"",
        "description": "Item description"
    },
    "item-travel-description": {
        "id": "item-travel-description",
        "defaultMessage": "\"We love getting out of the house and seeing the world. When we have free time, we want to travel!\"",
        "description": "Item description"
    },
    "item-fitness": {
        "id": "item-fitness",
        "defaultMessage": "Fitness",
        "description": "Item name"
    },
    "item-fitness-description": {
        "id": "item-fitness-description",
        "defaultMessage": "\"We are an active family that is always trying to eat right and get exercise. Fit is fun!\"",
        "description": "Item description"
    },
    "item-bargains": {
        "id": "item-bargains",
        "defaultMessage": "Bargains",
        "description": "Item name"
    },
    "item-bargains-description": {
        "id": "item-bargains-description",
        "defaultMessage": "\"We are constantly looking for ways to make our money work harder—whether it's through credit card points, investments or just smart planning.\"",
        "description": "Item description"
    },
    "item-none": {
        "id": "item-none",
        "defaultMessage": "None",
        "description": "Item name"
    },
    "item-none-description": {
        "id": "item-none-description",
        "defaultMessage": "\"None of the above describe my family.\"",
        "description": "Item description"
    },
    "item-drinks": {
        "id": "item-drinks",
        "defaultMessage": "Drinks",
        "description": "Item name"
    },
    "item-where-they-live": {
        "id": "item-where-they-live",
        "defaultMessage": "Where they live",
        "description": "Item name"
    },
    "item-the-people-they-associate-with": {
        "id": "item-the-people-they-associate-with",
        "defaultMessage": "The people they associate with",
        "description": "Item name"
    },
    "item-their-work-schedule": {
        "id": "item-their-work-schedule",
        "defaultMessage": "Their work schedule",
        "description": "Item name"
    },
    "item-how-they-spend-their-free-time": {
        "id": "item-how-they-spend-their-free-time",
        "defaultMessage": "How they spend their free time",
        "description": "Item name"
    },
    "item-how-they-use-technology": {
        "id": "item-how-they-use-technology",
        "defaultMessage": "How they use technology",
        "description": "Item name"
    },
    "item-how-much-time-they-spend-on-mobile-devices": {
        "id": "item-how-much-time-they-spend-on-mobile-devices",
        "defaultMessage": "How much time they spend on mobile devices",
        "description": "Item name"
    },
    "item-how-much-money-they-spend": {
        "id": "item-how-much-money-they-spend",
        "defaultMessage": "How much money they spend",
        "description": "Item name"
    },
    "item-how-much-money-they-save": {
        "id": "item-how-much-money-they-save",
        "defaultMessage": "How much money they save",
        "description": "Item name"
    },
    "item-where-they-shop": {
        "id": "item-where-they-shop",
        "defaultMessage": "Where they shop",
        "description": "Item name"
    },
    "item-where-they-go-on-holiday": {
        "id": "item-where-they-go-on-holiday",
        "defaultMessage": "Where they go on holiday",
        "description": "Item name"
    },
    "item-where-they-go-out-to-eat": {
        "id": "item-where-they-go-out-to-eat",
        "defaultMessage": "Where they go out to eat",
        "description": "Item name"
    },
    "item-how-often-they-go-out-to-eat": {
        "id": "item-how-often-they-go-out-to-eat",
        "defaultMessage": "How often they go out to eat",
        "description": "Item name"
    },
    "item-the-music-they-listen-to": {
        "id": "item-the-music-they-listen-to",
        "defaultMessage": "The music they listen to",
        "description": "Item name"
    },
    "item-the-movies-they-watch-in-the-cinema": {
        "id": "item-the-movies-they-watch-in-the-cinema",
        "defaultMessage": "The movies they watch in the cinema",
        "description": "Item name"
    },
    "item-the-entertainment-services-they-buy": {
        "id": "item-the-entertainment-services-they-buy",
        "defaultMessage": "The entertainment services they buy",
        "description": "Item name"
    },
    "item-the-computer-they-own": {
        "id": "item-the-computer-they-own",
        "defaultMessage": "The computer they own",
        "description": "Item name"
    },
    "item-the-grocery-brands-they-buy": {
        "id": "item-the-grocery-brands-they-buy",
        "defaultMessage": "The grocery brands they buy",
        "description": "Item name"
    },
    "item-the-clothing-brands-they-buy": {
        "id": "item-the-clothing-brands-they-buy",
        "defaultMessage": "The clothing brands they buy",
        "description": "Item name"
    },
    "item-the-car-they-drive": {
        "id": "item-the-car-they-drive",
        "defaultMessage": "The car they drive",
        "description": "Item name"
    },
    "item-the-investments-they-make": {
        "id": "item-the-investments-they-make",
        "defaultMessage": "The investments they make",
        "description": "Item name"
    },
    "item-quick-service-restaurants-": {
        "id": "item-quick-service-restaurants-",
        "defaultMessage": "Quick service restaurants ",
        "description": "Item name"
    },
    "item-loving": {
        "id": "item-loving",
        "defaultMessage": "Loving",
        "description": "Item name"
    },
    "item-happy": {
        "id": "item-happy",
        "defaultMessage": "Happy",
        "description": "Item name"
    },
    "item-a-team": {
        "id": "item-a-team",
        "defaultMessage": "A team",
        "description": "Item name"
    },
    "item-optimistic": {
        "id": "item-optimistic",
        "defaultMessage": "Optimistic",
        "description": "Item name"
    },
    "item-thriving": {
        "id": "item-thriving",
        "defaultMessage": "Thriving",
        "description": "Item name"
    },
    "item-connected": {
        "id": "item-connected",
        "defaultMessage": "Connected",
        "description": "Item name"
    },
    "item-time-crunched": {
        "id": "item-time-crunched",
        "defaultMessage": "Time-crunched",
        "description": "Item name"
    },
    "item-concerned-about-money": {
        "id": "item-concerned-about-money",
        "defaultMessage": "Concerned about money",
        "description": "Item name"
    },
    "item-just-getting-by": {
        "id": "item-just-getting-by",
        "defaultMessage": "Just getting by",
        "description": "Item name"
    },
    "item-stressed": {
        "id": "item-stressed",
        "defaultMessage": "Stressed",
        "description": "Item name"
    },
    "item-worried": {
        "id": "item-worried",
        "defaultMessage": "Worried",
        "description": "Item name"
    }
});