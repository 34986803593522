import { connect } from "react-redux";
import KeySelector from "../components/KeySelector";
import { selectGroup, selectKey } from "../actions/groups";

function mapStateToProps(state) {
  return {
    selected: state.groups.selected,
    keys: state.groups.selectedKeys,
    selectedKey: state.groups.selectedKey,
    replaceWithMums:
      state.intl.selectedLocale === "en" &&
      (state.countries.selected === "uk" ||
        state.countries.selected === "australia"),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    changeGroup: (key) => {
      dispatch(selectGroup(key));
    },
    changeKey: (key) => {
      dispatch(selectKey(key));
    },
  };
}

function mergeProps(stateProps, dispatchProps, ownProps) {
  return { ...ownProps, ...stateProps, ...dispatchProps };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(KeySelector);
