import React from "react";
import ReactDOM from "react-dom";
import classnames from "classnames";

export default class TransitionHeight extends React.Component {
  constructor() {
    super();

    this.state = {
      heightSet: false,
      height: 10000,
      immediate: true,
    };
  }

  componentDidMount() {
    var node = ReactDOM.findDOMNode(this);
    this.setState({
      height: Math.max(node.offsetHeight, node.clientHeight),
      heightSet: true,
    });

    setTimeout(() => {
      this.setState({
        immediate: false,
      });
    }, 1);
  }

  render() {
    const style = {
      maxHeight: this.state.height,
    };

    const classes = classnames(
      "transition-height",
      { "without-transition": this.state.immediate },
      { "has-sized": this.state.heightSet },
      { "is-active": this.props.isVisible }
    );

    return (
      <div style={style} className={classes}>
        {this.props.children}
      </div>
    );
  }
}
