import { IntlProvider } from "react-intl";
import { connect } from "react-redux";

function mapStateToProps(state) {
  return { ...{}, ...state.intl.locales[state.intl.selectedLocale] };
}

function mapDispatchToProps(dispatch) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(IntlProvider);
