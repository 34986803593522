import React from "react";
import classnames from "classnames";
import Viz1 from "../components/Viz1";
import Viz2 from "../components/Viz2";
import Viz3 from "../components/Viz3";
import Viz4 from "../components/Viz4";
import Viz5 from "../components/Viz5";
import { groupKeys } from "../strings/strings";
import { FormattedMessage } from "react-intl";

export default class StoryChartItem extends React.Component {
  render() {
    const item = this.props.item;
    const keys = this.props.keys;
    const showKeyTitles = this.props.showKeyTitles;
    const viz = this.props.viz;

    switch (viz) {
      case 5:
        return (
          <Viz5 {...this.props} replaceWithMums={this.props.replaceWithMums} />
        );
        break;
      case 4:
        return (
          <Viz4 {...this.props} replaceWithMums={this.props.replaceWithMums} />
        );
        break;
      case 3:
        return (
          <Viz3 {...this.props} replaceWithMums={this.props.replaceWithMums} />
        );
        break;
      case 2:
        return (
          <Viz2 {...this.props} replaceWithMums={this.props.replaceWithMums} />
        );
        break;
      case 1:
      default:
        return (
          <Viz1 {...this.props} replaceWithMums={this.props.replaceWithMums} />
        );
    }
  }
}
