import React from "react";
import { isServer } from "../utils/helpers";

export default class DownloadButton extends React.Component {
  render() {
    const base = "/";
    const language = this.props.language;
    const country = this.props.country;
    const story = this.props.story;
    const key = this.props.selectedKey || this.props.group;
    let url =
      base + story + "-" + key + "-" + country + "-" + language + ".png";

    if (!isServer()) {
      url = window.mtp.downloadsLocation + url;
    }

    const prefix = !isServer() ? window.mtp.assetLocation : "";

    

    return (
      <a href={url} target="_blank" download>
        <svg
          className="icon"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 20 20"
          style={{
            fill: "#ffd600",
          }}
        >
          <title>arrows</title>
          <path d="M10.1,15.54a1.29,1.29,0,0,1-1.29-1.29v-13a1.29,1.29,0,1,1,2.58,0v9.85L15.23,7.3a1.29,1.29,0,1,1,1.82,1.82l-6,6A1.29,1.29,0,0,1,10.1,15.54Z" />
          <path d="M10.1,15.54a1.29,1.29,0,0,1-.89-0.36L3.12,9.32A1.29,1.29,0,1,1,4.91,7.46L11,13.32A1.29,1.29,0,0,1,10.1,15.54Z" />
          <path d="M18.71,19.93H1.29A1.29,1.29,0,0,1,0,18.64V14.25a1.29,1.29,0,1,1,2.58,0v3.09H17.42V14.25a1.29,1.29,0,1,1,2.58,0v4.38A1.29,1.29,0,0,1,18.71,19.93Z" />
        </svg>
      </a>
    );
  }
}
