import React from "react";
import { about as messages } from "../strings/strings";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import Footer from "../components/Footer";

export default class About extends React.Component {
  render() {
    return (
      <div>
        <div className="page-content">
          <div className="page-content__header page-content__header--full">
            <h1>
              <FormattedMessage {...messages["title"]} />
            </h1>
            <h2>
              <FormattedMessage {...messages["sub-title"]} />
              <span className="sub-heading">
                <FormattedMessage {...messages["sub-title-small"]} />
              </span>
            </h2>
          </div>

          <div className="page-content__body page-content__body--small g">
            <div className="gi">
              <h2 className="c-tech">
                <FormattedMessage {...messages["meth-header"]} />{" "}
              </h2>
              <p>
                <FormattedMessage {...messages["meth-para1"]} />{" "}
              </p>
              <p>
                <FormattedMessage {...messages["meth-para2"]} />{" "}
              </p>
              <p>
                <strong className="c-tech">
                  <FormattedMessage {...messages["meth-para3"]} />{" "}
                </strong>
              </p>
            </div>
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <h2 className="c-consumer">
                <FormattedMessage {...messages["parent-header"]} />{" "}
              </h2>
              <p>
                <FormattedMessage {...messages["parent-para1"]} />{" "}
              </p>
              <p>
                <strong>
                  <FormattedMessage {...messages["parent-para2"]} />{" "}
                </strong>
              </p>
              <dl className="g g--tight">
                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-geny-header"]} />{" "}
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-geny-text"]} />{" "}
                  </dd>
                </div>

                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-genx-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-genx-text"]} />
                  </dd>
                </div>

                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-boomer-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-boomer-text"]} />
                  </dd>
                </div>
              </dl>
              <p>
                <strong>
                  <FormattedMessage {...messages["parent-para3"]} />
                </strong>
              </p>
              <dl className="g g--tight">
                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-new-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-new-text"]} />
                  </dd>
                </div>
                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-young-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-young-text"]} />
                  </dd>
                </div>
                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-school-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-school-text"]} />
                  </dd>
                </div>
                <div className="gi gi--half@md">
                  <dt className="c-consumer">
                    <FormattedMessage {...messages["parent-teen-header"]} />
                  </dt>
                  <dd>
                    <FormattedMessage {...messages["parent-teen-text"]} />
                  </dd>
                </div>
              </dl>
            </div>
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <h2 className="c-family">
                <FormattedMessage {...messages["extra-header"]} />{" "}
              </h2>
              <p className="styled-link">
                <FormattedHTMLMessage {...messages["extra-para1"]} />{" "}
              </p>
            </div>
          </div>
          <div className="page-content__footer page-content__footer--tight g">
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
