import { defineMessages } from 'react-intl';
    export const parents = defineMessages({
    "nationality": {
        "id": "parents-nationality",
        "defaultMessage": "the UK",
        "description": "Parent group item"
    },
    "multiple": {
        "id": "parents-multiple",
        "defaultMessage": "surveyed parents in {nationality}",
        "description": "Parent group item"
    },
    "gendermums": {
        "id": "parents-gendermums",
        "defaultMessage": "surveyed moms in {nationality}",
        "description": "Parent group item"
    },
    "genderdads": {
        "id": "parents-genderdads",
        "defaultMessage": "surveyed dads in {nationality} ",
        "description": "Parent group item"
    },
    "geny": {
        "id": "parents-geny",
        "defaultMessage": "surveyed Millennial parents in {nationality} ",
        "description": "Parent group item"
    },
    "genx": {
        "id": "parents-genx",
        "defaultMessage": "surveyed Generation X parents in {nationality} ",
        "description": "Parent group item"
    },
    "genboomers": {
        "id": "parents-genboomers",
        "defaultMessage": "surveyed Boomer parents in {nationality} ",
        "description": "Parent group item"
    },
    "stagenew": {
        "id": "parents-stagenew",
        "defaultMessage": "surveyed new parents in {nationality}",
        "description": "Parent group item"
    },
    "stageyoung": {
        "id": "parents-stageyoung",
        "defaultMessage": "surveyed parents of young children in {nationality}",
        "description": "Parent group item"
    },
    "stageschool": {
        "id": "parents-stageschool",
        "defaultMessage": "surveyed parents of schoolchildren in {nationality}",
        "description": "Parent group item"
    },
    "stageteen": {
        "id": "parents-stageteen",
        "defaultMessage": "surveyed parents of teens in {nationality} ",
        "description": "Parent group item"
    },
    "australia": {
        "id": "parents-australia",
        "defaultMessage": "Australia",
        "description": "Parent group item"
    },
    "brazil": {
        "id": "parents-brazil",
        "defaultMessage": "Brazil",
        "description": "Parent group item"
    },
    "mexico": {
        "id": "parents-mexico",
        "defaultMessage": "Mexico",
        "description": "Parent group item"
    },
    "canada": {
        "id": "parents-canada",
        "defaultMessage": "Canada",
        "description": "Parent group item"
    },
    "spain": {
        "id": "parents-spain",
        "defaultMessage": "Spain",
        "description": "Parent group item"
    },
    "uk": {
        "id": "parents-uk",
        "defaultMessage": "the UK",
        "description": "Parent group item"
    },
    "us": {
        "id": "parents-us",
        "defaultMessage": "the US",
        "description": "Parent group item"
    },
    "tab-all": {
        "id": "parents-tab-all",
        "defaultMessage": "All parents",
        "description": "Parent group item"
    },
    "tab-gender": {
        "id": "parents-tab-gender",
        "defaultMessage": "Gender",
        "description": "Parent group item"
    },
    "tab-generation": {
        "id": "parents-tab-generation",
        "defaultMessage": "Generation",
        "description": "Parent group item"
    },
    "tab-stage": {
        "id": "parents-tab-stage",
        "defaultMessage": "Stage",
        "description": "Parent group item"
    },
    "select-gender": {
        "id": "parents-select-gender",
        "defaultMessage": "Select gender",
        "description": "Parent group item"
    },
    "select-generation": {
        "id": "parents-select-generation",
        "defaultMessage": "Select generation",
        "description": "Parent group item"
    },
    "select-stage": {
        "id": "parents-select-stage",
        "defaultMessage": "Select stage of parenting",
        "description": "Parent group item"
    },
    "button-all": {
        "id": "parents-button-all",
        "defaultMessage": "All",
        "description": "Parent group item"
    },
    "button-gendermums": {
        "id": "parents-button-gendermums",
        "defaultMessage": "Moms",
        "description": "Parent group item"
    },
    "button-genderdads": {
        "id": "parents-button-genderdads",
        "defaultMessage": "Dads",
        "description": "Parent group item"
    },
    "button-geny": {
        "id": "parents-button-geny",
        "defaultMessage": "Millennials",
        "description": "Parent group item"
    },
    "button-genx": {
        "id": "parents-button-genx",
        "defaultMessage": "Generation X",
        "description": "Parent group item"
    },
    "button-genboomers": {
        "id": "parents-button-genboomers",
        "defaultMessage": "Boomers",
        "description": "Parent group item"
    },
    "button-stagenew": {
        "id": "parents-button-stagenew",
        "defaultMessage": "New parents",
        "description": "Parent group item"
    },
    "button-stageyoung": {
        "id": "parents-button-stageyoung",
        "defaultMessage": "Parents of young children",
        "description": "Parent group item"
    },
    "button-stageschool": {
        "id": "parents-button-stageschool",
        "defaultMessage": "Parents of schoolchildren",
        "description": "Parent group item"
    },
    "button-stageteen": {
        "id": "parents-button-stageteen",
        "defaultMessage": "Parents of teens",
        "description": "Parent group item"
    },
    "short-gendermums": {
        "id": "parents-short-gendermums",
        "defaultMessage": "Moms",
        "description": "Parent group item"
    },
    "short-genderdads": {
        "id": "parents-short-genderdads",
        "defaultMessage": "Dads",
        "description": "Parent group item"
    },
    "short-geny": {
        "id": "parents-short-geny",
        "defaultMessage": "Mill.",
        "description": "Parent group item"
    },
    "short-genx": {
        "id": "parents-short-genx",
        "defaultMessage": "Gen X",
        "description": "Parent group item"
    },
    "short-genboomers": {
        "id": "parents-short-genboomers",
        "defaultMessage": "Boomer",
        "description": "Parent group item"
    },
    "short-stagenew": {
        "id": "parents-short-stagenew",
        "defaultMessage": "New",
        "description": "Parent group item"
    },
    "short-stageyoung": {
        "id": "parents-short-stageyoung",
        "defaultMessage": "Young",
        "description": "Parent group item"
    },
    "short-stageschool": {
        "id": "parents-short-stageschool",
        "defaultMessage": "School",
        "description": "Parent group item"
    },
    "short-stageteen": {
        "id": "parents-short-stageteen",
        "defaultMessage": "Teens",
        "description": "Parent group item"
    },
    "note-geny": {
        "id": "parents-note-geny",
        "defaultMessage": "\"Millennial parents\" indicate parents between 25 and 34 years old.",
        "description": "Parent group item"
    },
    "note-genx": {
        "id": "parents-note-genx",
        "defaultMessage": "\"Generation X parents\" indicate parents between 35 and 49 years old.",
        "description": "Parent group item"
    },
    "note-genboomers": {
        "id": "parents-note-genboomers",
        "defaultMessage": "\"Baby boomer parents\" indicate parents between 50 and 65 years old.",
        "description": "Parent group item"
    },
    "note-stagenew": {
        "id": "parents-note-stagenew",
        "defaultMessage": "\"New parents\" include expecting parents to parents with a child less than 1 year old.",
        "description": "Parent group item"
    },
    "note-stageyoung": {
        "id": "parents-note-stageyoung",
        "defaultMessage": "\"Parents of young children\" include parents with a child between 1 and 5 years old.",
        "description": "Parent group item"
    },
    "note-stageschool": {
        "id": "parents-note-stageschool",
        "defaultMessage": "\"Parents of schoolchildren\" include parents with a child between 6 and 12 years old.",
        "description": "Parent group item"
    },
    "note-stageteen": {
        "id": "parents-note-stageteen",
        "defaultMessage": "\"Parents of teens\" include parents with a child between 13 and 17 years old.",
        "description": "Parent group item"
    }
});