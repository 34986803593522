import { defineMessages } from 'react-intl';
    export const sources = defineMessages({
    "source-question-tech1": {
        "id": "source-question-tech1",
        "defaultMessage": "How many of the following devices does your household own?",
        "description": "Source question"
    },
    "source-notes-tech1": {
        "id": "source-notes-tech1",
        "defaultMessage": "Percentage answers indicate parents who answered they own one or more of that device. Average number of devices owned is taken as the mean of all respondents for that device. Examples of digital media receivers include Apple TV, Google Chromecast and Roku. Examples of wearables include Fitbit and Pebble.",
        "description": "Source notes"
    },
    "source-question-tech5": {
        "id": "source-question-tech5",
        "defaultMessage": "How much do you agree with the following statements about how mobile devices are involved with your parenting? If you are expecting your first child, please indicate how you plan to involve mobile devices in your parenting.",
        "description": "Source question"
    },
    "source-notes-tech5": {
        "id": "source-notes-tech5",
        "defaultMessage": "Parents who answered that they agree or agree completely with the statement. Answers only include parents who own a mobile device.",
        "description": "Source notes"
    },
    "source-question-fam1": {
        "id": "source-question-fam1",
        "defaultMessage": "How much do you agree with the following statements?",
        "description": "Source question"
    },
    "source-notes-fam1": {
        "id": "source-notes-fam1",
        "defaultMessage": "Compared to their own parents, the percentage of surveyed parents who agree with this statement.",
        "description": "Source notes"
    },
    "source-question-fam2": {
        "id": "source-question-fam2",
        "defaultMessage": "How much would you agree that the following statements describe your family?",
        "description": "Source question"
    },
    "source-notes-fam2": {
        "id": "source-notes-fam2",
        "defaultMessage": "Parents who answered that they agree or agree completely with the statement.",
        "description": "Source notes"
    },
    "source-question-fam3": {
        "id": "source-question-fam3",
        "defaultMessage": "Which of the following best describes your family?",
        "description": "Source question"
    },
    "source-question-fam4a": {
        "id": "source-question-fam4a",
        "defaultMessage": "How do you and your spouse/partner split up the following household tasks?",
        "description": "Source question"
    },
    "source-notes-fam4a": {
        "id": "source-notes-fam4a",
        "defaultMessage": "Parents who answered \"I do a little more/much more/all.\" Answers only include parents where a co-parent is present.",
        "description": "Source notes"
    },
    "source-question-fam4b": {
        "id": "source-question-fam4b",
        "defaultMessage": "How do you and your spouse/partner split up the following household tasks? ",
        "description": "Source question"
    },
    "source-notes-fam4b": {
        "id": "source-notes-fam4b",
        "defaultMessage": "Parents who answered \"My partner does a little more/much more/all.\" Answers only include parents where a co-parent is present.",
        "description": "Source notes"
    },
    "source-question-fam5": {
        "id": "source-question-fam5",
        "defaultMessage": "Thinking about before you were pregnant compared to now, how much will your child impact/influence your decisions when it comes to the following?",
        "description": "Source question"
    },
    "source-notes-fam5": {
        "id": "source-notes-fam5",
        "defaultMessage": "Parents who answered their child impacts or impacts greatly that aspect of their lives.",
        "description": "Source notes"
    },
    "source-question-con1": {
        "id": "source-question-con1",
        "defaultMessage": "Please select the categories you and/or your family use or make purchases from. ",
        "description": "Source question"
    },
    "source-question-con2": {
        "id": "source-question-con2",
        "defaultMessage": "How does your child contribute to your decisions in these categories?",
        "description": "Source question"
    },
    "source-notes-con2": {
        "id": "source-notes-con2",
        "defaultMessage": "Parents who answered that their child asks for products/services in this category or that just having a child influences their decisions in this category. Answers only include parents whose child contributes to decision-making.",
        "description": "Source notes"
    },
    "source-question-con3a": {
        "id": "source-question-con3a",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3a": {
        "id": "source-notes-con3a",
        "defaultMessage": "Parents who answered \"You\" exclusively. Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    },
    "source-question-con3b": {
        "id": "source-question-con3b",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3b": {
        "id": "source-notes-con3b",
        "defaultMessage": "Parents who answered \"You.\" Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    },
    "source-question-con3c": {
        "id": "source-question-con3c",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3c": {
        "id": "source-notes-con3c",
        "defaultMessage": "Parents who answered \"Spouse/Partner.\" Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    },
    "source-question-con3d": {
        "id": "source-question-con3d",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3d": {
        "id": "source-notes-con3d",
        "defaultMessage": "Parents who answered \"Your child.\" Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    },
    "source-question-con3e": {
        "id": "source-question-con3e",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3e": {
        "id": "source-notes-con3e",
        "defaultMessage": "Parents who answered \"Other child.\" Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    },
    "source-question-con3f": {
        "id": "source-question-con3f",
        "defaultMessage": "Who plays a role in making decisions about the following categories for your household?",
        "description": "Source question"
    },
    "source-notes-con3f": {
        "id": "source-notes-con3f",
        "defaultMessage": "Parents who answered \"Other adult.\" Answers only include parents who use or make purchases from that consumer category.",
        "description": "Source notes"
    }
});