import { groups as constants } from "../constants/constants";
import find from "lodash/find";
import config from "../config.json";

var intitialState = {
  options: config.groups,
  selected: null,
  selectedKeys: [],
  selectedKey: null,
};

function selectGroup(groups, id) {
  var group = find(groups.options, (group) => {
    return group.id === id;
  });

  var newState = {
    ...groups,
    ...{
      selected: group.id,
      selectedKeys: group.keys,
      selectedKey: null,
    },
  };

  return newState;
}

intitialState.selected = intitialState.options[0].id;
intitialState.selectedKeys = intitialState.options[0].keys;
intitialState.selectedKey = null;

export default function groups(state = intitialState, action) {
  switch (action.type) {
    case constants.SELECT_GROUP:
      return selectGroup(state, action.key);
    case constants.SELECT_KEY:
      return {
        ...state,
        ...{
          selectedKey: action.key,
        },
      };
    default:
      return state;
  }
}
