import { stories as constants } from "../constants/constants";
import { combineReducers } from "redux";
import find from "lodash/find";
import config from "../config";

function selected(state = null, action) {
  switch (action.type) {
    case constants.SELECT_STORY:
      return action.story;
    default:
      return state;
  }
}

function isFetching(state = false, action) {
  switch (action.type) {
    case constants.REQUEST_COUNTRYDATA:
      return true;
    case constants.RECEIVE_COUNTRYDATA:
    case constants.RECEIVE_COUNTRYDATAERROR:
      return false;
    default:
      return state;
  }
}

function stories(state = config.stories, action) {
  switch (action.type) {
    case constants.REQUEST_STORYDATA:
    case constants.RECEIVE_STORYDATA:
    case constants.RECEIVE_STORYDATAERROR:
      return state;
    case constants.RECEIVE_COUNTRYDATA:
      return state.map((story) => {
        let storyData = find(action.data, ({ data }) => data.id === story.id);
        console.log({ storyData });
        storyData = storyData || { data: null };
        return {
          ...story,
          ...{
            data: {
              ...story.data,
              ...{
                [action.country]: storyData.data,
              },
            },
          },
        };
      });
    default:
      return state;
  }
}

export default combineReducers({
  selected,
  stories,
  isFetching,
});
