import store from "./store";

import Main from "./layouts/Main";
import MainWithoutMenu from "./layouts/MainWithoutMenu";

import Intro from "./views/Intro";
import About from "./views/About";
import CountryPage from "./views/CountryPage";
import StoryPage from "./views/StoryPage";

import { switchLocale } from "./actions/intl";
import { setCountry } from "./actions/countries";
import { selectParent, selectChild, close } from "./actions/navigation";
import { setStory, maybeFetchCountryData } from "./actions/stories";
import { selectGroup, selectKey } from "./actions/groups";

const routes = [
  {
    path: "/",
    onEnter: () => {
      window.location = "#/en";
      //history.pushState(null, '#/en');
    },
  },
  {
    path: "/:locale",
    component: MainWithoutMenu,
    indexRoute: { component: Intro },
    onEnter: (nextState) => {
      store.dispatch(switchLocale(nextState.params.locale));
    },
    childRoutes: [
      {
        path: "404",
        component: About,
      },
      {
        path: "about",
        component: About,
      },
    ],
  },
  {
    path: "/:locale",
    component: Main,
    onEnter: (nextState, transtion, callback) => {
      let locale = nextState.params.locale || "en";
      store.dispatch(close());
      store.dispatch(switchLocale(locale, callback));
    },
    childRoutes: [
      {
        path: "stories/:country",
        component: CountryPage,
        onEnter: (nextState, transition, callback) => {
          store.dispatch(close());
          store.dispatch(selectParent());
          store.dispatch(setCountry(nextState.params.country));
          store.dispatch(
            maybeFetchCountryData(nextState.params.country, callback)
          );
        },
      },
      {
        path: "stories/:country/:slug",
        component: StoryPage,
        onEnter: (nextState, transition, callback) => {
          store.dispatch(close());
          store.dispatch(setStory(nextState.params.slug));
          store.dispatch(selectGroup("all"));
          store.dispatch(selectChild(nextState.params.slug));
          store.dispatch(setCountry(nextState.params.country));

          console.log({ nextState });

          // Querystrings for groups and keys
          if (nextState.location.query && nextState.location.query.group) {
            store.dispatch(selectGroup(nextState.location.query.group));
          }

          if (
            nextState.location.query &&
            nextState.location.query.group &&
            nextState.location.query.key
          ) {
            store.dispatch(selectGroup(nextState.location.query.group));
            store.dispatch(selectKey(nextState.location.query.key));
          }

          store.dispatch(
            maybeFetchCountryData(nextState.params.country, callback)
          );
        },
      },
    ],
  },
];

export default routes;
