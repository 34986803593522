import React from "react";
import classnames from "classnames";
import ReactCSSTransitionGroup from "react-transition-group/CSSTransitionGroup";

export default class Donut extends React.Component {
  render() {
    const percentage = this.props.percentage;
    const isSmall = this.props.small;
    const includeNumber = !this.props.hideNumber;
    const inset = percentage > 50;
    const isNull = isNaN(percentage);

    const chartClasses = classnames("donut");

    const wrapperClasses = classnames(
      "donut__wrapper",
      { "donut--small": isSmall },
      { "donut--has-number": includeNumber },
      { "donut--no-data": isNull }
    );

    const dasharray = Math.round((314 / 100) * percentage) + " 315";

    return (
      <ReactCSSTransitionGroup
        transitionName="donut"
        transitionAppear={true}
        transitionAppearTimeout={1}
        transitionEnterTimeout={1}
        transitionLeaveTimeout={1}
      >
        <div className={chartClasses}>
          <svg viewBox="0 0 100 100">
            <circle r="50" cx="50" cy="50" strokeDasharray={dasharray} />
          </svg>
          {includeNumber && (
            <span
              className={classnames("donut__percentage-num", "mega-number")}
            >
              {percentage}
              <span className="mega-number__percentage">%</span>
            </span>
          )}
        </div>
      </ReactCSSTransitionGroup>
    );
  }
}
