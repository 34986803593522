import {
  createHistory,
  createHashHistory,
  createMemoryHistory,
  useBasename,
} from "history";
import { isServer } from "./helpers";

let history;

if (isServer()) {
  history = useBasename(createMemoryHistory)({
    basename: "",
  });
} else {
  history = useBasename(createHistory)({
    basename: window.mtp.basename,
  });

  history = useBasename(createHashHistory)({
    queryKey: false,
    basename: window.mtp.basename,
  });
}

export default history;
