import React from "react";
import Link from "../containers/Link";
import { intro as messages, navigation } from "../strings/strings";
import { FormattedMessage } from "react-intl";
import { dataCountries } from "../constants/constants";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import CountrySelector from "../components/CountrySelector";
import { injectIntl } from "react-intl";
import { isServer } from "../utils/helpers";

class Intro extends React.Component {
  render() {
    let pageTitle = this.props.intl.formatMessage(messages["title"]);

    const prefix = !isServer() ? window.mtp.assetLocation : "";

    return (
      <div className="page-content">
        <PageHeader title={pageTitle} />
        <div className="page-content__body">
          <div className="g">
            <div className="gi gi--half@lg">
              <h2 className="page-content__title page-content__title--large">
                <FormattedMessage {...messages["heading"]} />
              </h2>
              <p className="page-content__lede">
                <FormattedMessage {...messages["lede"]} />
              </p>
            </div>
            <div className="gi gi--half@lg">
              <CountrySelector countries={dataCountries} />
            </div>
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <h2 className="page-content__title">
                <FormattedMessage {...messages["discover"]} />
              </h2>
              <p>
                <FormattedMessage {...messages["discover1"]} />
              </p>
              <p>
                <FormattedMessage {...messages["discover2"]} />
              </p>
            </div>
            <div className="gi gi--third@md page-content__illustration page-content__illustration--family">
              <img src={prefix + "/images/Family_life_cropped.jpg"} />
              <h3>
                <FormattedMessage {...navigation["fam"]} />
              </h3>
              <p>
                <FormattedMessage {...messages["theme-family2"]} />
              </p>
            </div>
            <div className="gi gi--third@md page-content__illustration page-content__illustration--consumer">
              <img src={prefix + "/images/Spending_cropped.jpg"} />
              <h3>
                <FormattedMessage {...navigation["con"]} />
              </h3>
              <p>
                <FormattedMessage {...messages["theme-spending2"]} />
              </p>
            </div>
            <div className="gi gi--third@md page-content__illustration page-content__illustration--tech">
              <img src={prefix + "/images/Technology_cropped.jpg"} />
              <h3>
                <FormattedMessage {...navigation["tech"]} />
              </h3>
              <p>
                <FormattedMessage {...messages["theme-tech2"]} />
              </p>
            </div>
          </div>
        </div>
        <div className="page-content__footer page-content__footer--tight">
          <div className="g">
            <div className="gi">
              <hr />
            </div>
            <div className="gi">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default injectIntl(Intro);
