import React from "react";
import history from "../utils/history";
import { locales } from "../constants/constants";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "react-router";
import TransitionHeight from "./TransitionHeight";
import classnames from "classnames";

class LocaleSelector extends React.Component {
  render() {
    let values = Object.keys(locales).map((key) => locales[key]);

    let options = values.map((locale, i) => {
      return (
        <Link
          tabIndex={this.props.menuOpen ? 0 : -1}
          onClick={this.props.hideMenu}
          className="locale-selector__locale"
          key={locale.locale}
          to={locale.slug}
        >
          {locale.text}{" "}
          <span className="locale-selector__code">{locale.id}</span>
        </Link>
      );
    });

    let locale = locales[this.props.locale.toUpperCase()];
    let animation = this.props.menuOpen ? "slideDown" : "slideUp";

    let localeClasses = classnames("locale-selector", {
      "locale-selector--open": this.props.menuOpen,
    });

    return (
      <div className={localeClasses}>
        <button
          className="locale-selector__selected"
          onClick={this.props.toggleMenu}
        >
          <span className="locale-selector__text">{locale.text}</span>{" "}
          {locale.id}
        </button>

        <TransitionHeight isVisible={this.props.menuOpen}>
          <div className="locale-selector__locales" key={"locales"}>
            {options}
          </div>
        </TransitionHeight>
      </div>
    );
  }
}

export default injectIntl(LocaleSelector);
