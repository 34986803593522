import React from "react";
import classnames from "classnames";
import { parentStrings as messages } from "../strings/strings";
import { FormattedMessage } from "react-intl";

export default class GroupSelector extends React.Component {
  render() {
    var options = this.props.options;

    return (
      <div className="group-selector">
        {options.map((option, i) => {
          let classes = classnames("group-selector__item", {
            "is-active": option.id === this.props.selected,
          });
          return (
            <button
              key={i}
              className={classes}
              onClick={this.props.changeGroup.bind(null, option.id)}
            >
              <FormattedMessage {...messages["tab-" + option.id]} />
            </button>
          );
        })}
      </div>
    );
  }
}
