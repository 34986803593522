import React from "react";
import classnames from "classnames";
import Donut from "./Donut";
import { parentStrings, items } from "../strings/strings";
import { FormattedMessage } from "react-intl";
import { slugify } from "../utils/helpers";

export default class Viz5 extends React.Component {
  render() {
    const item = this.props.item;
    const keys = this.props.keys;
    const showKeyTitles = this.props.showKeyTitles;

    const itemClasses = classnames(
      "story-chart-item",
      "story-chart-item--viz5",
      { "story-chart-item--has-keys": this.props.showKeyTitles },
      { "story-chart-item--is-odd": this.props.keys.length === 3 }
    );

    return (
      <div className={itemClasses}>
        <div className="story-chart-item__header">
          <div className="story-chart-item__title">
            <FormattedMessage {...items["item-" + slugify(item.name)]} />
          </div>
          {item.description && (
            <div className="story-chart-item__description">
              <FormattedMessage
                {...items["item-" + slugify(item.name) + "-description"]}
              />
            </div>
          )}
        </div>
        <div className="story-chart-item__keys">
          {keys.map((key, j) => {
            let percentage = item[key].percentage;
            let hasNum = false;
            let keyTitle = "";
            if (showKeyTitles) {
              keyTitle = (
                <FormattedMessage {...parentStrings["short-" + key]} />
              );
              if (this.props.replaceWithMums && key === "gendermums") {
                keyTitle = "Mums";
              }
            }

            return (
              <div
                key={j}
                className="story-chart-item__key story-chart-item__content"
              >
                {showKeyTitles && (
                  <div className="story-chart-item__key-title">{keyTitle}</div>
                )}

                <div className="story-chart-item__charts">
                  <div className="story-chart-item__chart">
                    <Donut percentage={percentage} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}
