export const intl = {
    SELECT_LOCALE: 'SELECT_LOCALE',
    INVALIDATE_LOCALE : 'INVALIDATE_LOCALE',
    REQUEST_LOCALE : 'REQUEST_LOCALE',
    RECEIVE_LOCALE : 'RECEIVE_LOCALE',
    OPEN: 'OPEN_LOCALES',
    CLOSE: 'CLOSE_LOCALES',
    TOGGLE: 'TOGGLE_LOCALES'
}

export const navigation = {
    SELECT_PARENT: 'SELECT_PARENT',
    SELECT_CHILD: 'SELECT_CHILD',
    OPEN: 'OPEN',
    CLOSE: 'CLOSE',
    TOGGLE: 'TOGGLE',
    HAS_OPENED: 'HAS_OPENED'
}

export const countries = {
    SET_COUNTRY: 'SET_COUNTRY',
    OPEN: 'OPEN_COUNTRIES',
    CLOSE: 'CLOSE_COUNTRIES',
    TOGGLE: 'TOGGLE_COUNTRIES'
}

export const groups = {
    SELECT_GROUP: 'SELECT_GROUP',
    SELECT_KEY: 'SELECT_KEY'
}

export const stories = {
    SELECT_STORY: 'SELECT_STORY',
    REQUEST_STORYDATA : 'REQUEST_STORYDATA',
    RECEIVE_STORYDATA : 'RECEIVE_STORYDATA',
    RECEIVE_STORYDATAERROR : 'RECEIVE_STORYDATAERROR',

    REQUEST_COUNTRYDATA : 'REQUEST_COUNTRYDATA',
    RECEIVE_COUNTRYDATA : 'RECEIVE_COUNTRYDATA',
    RECEIVE_COUNTRYDATAERROR : 'RECEIVE_COUNTRYDATAERROR',
}


export const locales = {
    EN: {
        id: 'EN',
        slug: '/en',
        locale: 'en',
        text: 'I prefer English'
    },
    PT: {
        id: 'PT',
        slug: '/pt',
        locale: 'pt',
        text: 'Prefiro português'
    },
    ES: {
        id: 'ES',
        slug: '/es',
        locale: 'es',
        text: 'Prefiero español'
    },
    MX: {
        id: 'MX',
        slug: '/mx',
        locale: 'es_mx',
        text: 'Prefiero español'
    }
}

export const dataCountries = ['australia', 'brazil', 'canada', 'mexico', 'spain', 'uk', 'us'];
