import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import find from "lodash/find";
import { stories as storiesMessages, countryGroups } from "../strings/strings";
import GroupSelector from "../containers/GroupSelector";
import KeySelector from "../containers/KeySelector";
import StoryChart from "../components/StoryChart";

class Story extends React.Component {
  render() {
    const stories = this.props.stories;
    const story = find(stories, {"slug": this.props.selected});
    const classes = "story story--" + story.category;

    console.log({ selected: this.props.selected, stories, story });

    return (
      <div key={story.id} className={classes}>
        <div className="story__header">
          <h1>
            <FormattedMessage {...storiesMessages[story.id]} />
          </h1>
          <GroupSelector />
        </div>

        <div className="story__content">
          {!this.props.isFetching &&
            !this.props.error && [
              <KeySelector key={"keys"} />,
              <StoryChart
                key={"chart"}
                story={story}
                country={this.props.country}
                selectedKey={this.props.selectedKey}
                keys={this.props.keys}
                replaceWithMums={this.props.replaceWithMums}
              />,
            ]}

          {this.props.isFetching && <span className="message">Loading</span>}

          {this.props.error && (
            <span className="message">An error occurred.</span>
          )}
        </div>
      </div>
    );
  }
}

export default injectIntl(Story);
